import React from 'react';

import { StyledButton } from './wrappers';

export interface SwitchProps {
  /** From Id */
  id?: string;
  /** HTML name */
  name?: string;
  /** Checked state */
  checked?: boolean;
  /** Default value for checked state */
  defaultChecked?: boolean;
  /** onChange handler with checked state and event */
  onChange?: (checked: boolean, event: MouseEvent) => void;
  /** onClick handler with checked state and event */
  onClick?: (checked: boolean, event: MouseEvent) => void;
  /** Disabled state */
  disabled?: boolean;
  /** Loading state */
  loading?: boolean;
  /** Style prop */
  className?: string;
  /** Inline styles */
  style?: React.CSSProperties;
  /** Accessability label */
  'aria-label'?: string;
  /** Accessability labelledby */
  'aria-labelledby'?: string;
}

export const Switch = React.forwardRef<HTMLButtonElement, SwitchProps>(
  (
    {
      id,
      name,
      checked: controlledChecked,
      defaultChecked,
      onChange,
      onClick,
      disabled = false,
      loading = false,
      className,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledby,
      ...rest
    },
    ref,
  ) => {
    const [internalChecked, setInternalChecked] = React.useState(
      defaultChecked ?? false,
    );
    const isControlled = controlledChecked !== undefined;
    const currentChecked = isControlled ? controlledChecked : internalChecked;
    const shouldBeDisabled = disabled || loading;

    const handleClick = (event: React.MouseEvent): void => {
      if (shouldBeDisabled) return;

      const newChecked = !currentChecked;
      if (!isControlled) {
        setInternalChecked(newChecked);
      }

      if (onClick) {
        onClick(newChecked, event.nativeEvent);
      }
      onChange?.(newChecked, event.nativeEvent);
    };

    return (
      <StyledButton
        id={id}
        ref={ref}
        name={name}
        type="button"
        role="switch"
        className={className}
        disabled={shouldBeDisabled}
        $disabled={shouldBeDisabled}
        $loading={loading}
        $checked={currentChecked}
        aria-checked={currentChecked}
        aria-disabled={shouldBeDisabled}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledby}
        onClick={handleClick}
        {...rest}
      />
    );
  },
);

Switch.displayName = 'Switch';
