/* eslint-disable max-lines */
import * as t from 'io-ts';

import { LanguageKey } from '@transcend-io/internationalization';
import {
  ConsentBundleType,
  DecryptionStatus,
  IdentifierType,
  IsoCountryCode,
  IsoCountrySubdivisionCode,
  PreferenceQueryResponseItem,
  PreferenceStoreConsentFields,
  PreferenceStoreIdentifier,
  PreferenceStoreKeyConditionals,
  PreferenceStorePurposeResponse,
  PreferenceStoreSystemAttributes,
  PreferenceUpdateItem,
  RequestActionObjectResolver,
  RequestOrigin,
} from '@transcend-io/privacy-types';
import {
  FixedLengthString,
  type FixedLengthStringBrand,
  HttpMethod,
  NonEmptyString,
  partialRecord,
  valuesOf,
} from '@transcend-io/type-utils';

import { dbModelId, schemaToCodec } from '@main/schema-utils';

import {
  AmazonS3SupportedQuery,
  AWSSupportedQuery,
  AzureBlobServiceSupportedQuery,
  CdataOdbcSupportedCommand,
  ColumnEncryptionType,
  DynamoDBSupportedQuery,
  EncryptionType,
  FetchSubDatapointsApproach,
  GenericAWSSupportedQuery,
  KnownDataType,
  MongoSupportedQuery,
  RegexFilterActions,
  RegexFilterFunctions,
  SftpSupportedCommand,
  SftpSupportedFileType,
  SmbSupportedCommand,
  SnowflakeSchemaQueryOperation,
  SombraDataSubjectAuthMethod,
  SombraEmployeeAuthMethod,
  SQLQueryParameterType,
  SupportedBigQueryAPI,
  TableEncryptionType,
  UnstructuredClassificationMethod,
} from '../enums';
import {
  CustomFunctionExecutionOverridePayload,
  CustomFunctionExecutionResult,
  DeprecatedRequestIdentifiersInput,
  OAuthParametersGetCoreIdSchema,
  OAuthParametersGetCoreIdSchemaInput,
  OAuthParametersGetEmailSchema,
  OAuthParametersGetEmailSchemaInput,
  OAuthParametersGetProfilePictureSchema,
  OAuthParametersGetProfilePictureSchemaInput,
  OAuthParametersGetTokenBodySchema,
  SAMLSchema,
  SAMLSchemaInput,
  SombraProfile,
} from '../schema';
import type { OAuthParameters, OAuthSecrets } from '../tenant';

/**
 * Type Refinements
 *
 * All of sizes below assumes a base64 encoding
 */
export const JwtEcdsaKey: t.BrandC<
  t.StringC,
  FixedLengthStringBrand<384>
> = FixedLengthString(384);
/** Override types */
export type JwtEcdsaKey = t.TypeOf<typeof JwtEcdsaKey>;

export const JwtEcdsaPublicKey = FixedLengthString(288);
/** Override types */
export type JwtEcdsaPublicKey = t.TypeOf<typeof JwtEcdsaPublicKey>;

export const KeyEncryptionBase = FixedLengthString(44);
/** Override types */
export type KeyEncryptionBase = t.TypeOf<typeof KeyEncryptionBase>;

export const HmacNonceKey = FixedLengthString(64);
/** Override types */
export type HmacNonceKey = t.TypeOf<typeof HmacNonceKey>;

export const HmacSessionKey = FixedLengthString(64);
/** Override types */
export type HmacSessionKey = t.TypeOf<typeof HmacSessionKey>;

export const EcdhPrivateKey = FixedLengthString(64);
/** Override types */
export type EcdhPrivateKey = t.TypeOf<typeof EcdhPrivateKey>;

export const InternalKeyHash = FixedLengthString(44);
/** Override types */
export type InternalKeyHash = t.TypeOf<typeof InternalKeyHash>;

export const JwtAuthenticationPublicKey = FixedLengthString(288);
/** Overrides type */
export type JwtAuthenticationPublicKey = t.TypeOf<
  typeof JwtAuthenticationPublicKey
>;

export const ConsentIdentifierEncryptionKey = FixedLengthString(44);
/** Override types */
export type ConsentIdentifierEncryptionKey = t.TypeOf<
  typeof ConsentIdentifierEncryptionKey
>;

/** Key with ID instance. */
export interface SombraKeyWithId<K> {
  /** Key instance. */
  k: K;
  /** Key ID. */
  id: string;
}

export const SignedIdentifierPath = t.type({
  /** Name of identifier */
  identifierName: t.string,
  /** JSON path to that identifier */
  path: t.string,
});

/** Overrides type */
export type SignedIdentifierPath = t.TypeOf<typeof SignedIdentifierPath>;

/**
 * The context required for a SaaS integration, such as
 * credentials, base hosts, and allowed plaintext paths.
 */
export const SaaSContext = t.intersection([
  t.type({
    /** The map of templateVariableName:encryptedSecret */
    encryptedSecretMap: t.record(t.string, t.string),
    // TODO: https://github.com/transcend-io/main/issues/5738 - make sure null is not in allowed hosts
    /** The hosts that can be requested for this SaaS system */
    allowedHosts: t.array(t.union([t.null, t.string])),
    /** JSON keys that shouldn't be encrypted (like pagination keys) */
    allowedPlaintextPaths: t.array(t.string),
    /** The subdomain (if subdomain auth) */
    subdomain: t.union([t.undefined, t.string]),
  }),
  t.partial({
    /** Mapping where the key is the type of identifier, and value is JSON path to that identifier */
    allowedIdentifierPaths: t.array(SignedIdentifierPath),
  }),
]);

/** Type override */
export type SaaSContext = t.TypeOf<typeof SaaSContext>;

/**
 * The nonce sent to the customer to hold all context about the webhook
 */
export const FileEncryptionMetadata = t.intersection([
  t.type({
    /** AES GCM authentication tag as a base64 string */
    authTag: t.string,
    /** base64-encoded initialization vector */
    iv: t.string,
    /** Information about the file's type */
    fileType: t.type({
      /** The mimetype of the file */
      mime: t.string,
      /** The file extension */
      ext: t.union([t.string, t.null]),
    }),
    /** The DSR's request id */
    requestId: dbModelId('request'),
  }),
  t.partial({
    /** JWT iat */
    iat: t.number,
    /** The calculated checksum hash */
    checksum: t.string,
    /** The paths to use in hash that verifies object has not changed */
    checksumPaths: t.array(t.string),
    /** An ID used to identify a the HTTP request sent to Sombra associated with a request file download. */
    oneTimeId: t.string,
  }),
]);

/** Override types */
export type FileEncryptionMetadata = t.TypeOf<typeof FileEncryptionMetadata>;

/**
 * Metadata from sombra about a file-stream.
 */
export const StreamEncryptionMetadata = t.intersection([
  FileEncryptionMetadata,
  t.type({
    /** An ID used to identify a the HTTP request sent to Sombra associated with a request file download. */
    oneTimeId: t.string,
  }),
]);

/** Override types */
export type StreamEncryptionMetadata = t.TypeOf<
  typeof StreamEncryptionMetadata
>;

/** The input for the OAUTH_PARAMETERS tenant config field */
export const OAuthParametersInput = t.intersection([
  t.type({
    /** The Client ID of your privacy center's OAuth 2 application */
    CLIENT_ID: t.array(t.string),
    /** Configuration related to retrieving the Oauth Token */
    GET_TOKEN: t.intersection([
      t.type({
        /** The token URL for your OAuth API (authorization_code => access_token) e.g. https://api.acme.com/oauth/token */
        URL: t.string,
        /** Configuration for the body of the request that fetches the OAuth token */
        BODY: schemaToCodec(OAuthParametersGetTokenBodySchema),
      }),
      t.partial({
        /** The HTTP Method for fetching the OAUTH Token from your OAuth API */
        METHOD: valuesOf(HttpMethod),
        /** The headers to include when fetching the OAuth token */
        HEADERS: t.record(t.string, t.string),
      }),
    ]),
    /** Configuration related to fetching the core ID for the data subject */
    GET_CORE_ID: schemaToCodec(OAuthParametersGetCoreIdSchema),
    /** Configuration related to fetching the email for the data subject */
    GET_EMAIL: schemaToCodec(OAuthParametersGetEmailSchema),
  }),
  t.partial({
    /** Configuration related to fetching the data subject profile picture */
    GET_PROFILE_PICTURE: schemaToCodec(OAuthParametersGetProfilePictureSchema),
  }),
]);

/** Override types */
export type OAuthParametersInput = t.TypeOf<typeof OAuthParametersInput>;

export const PatchOAuthParametersInput = t.partial({
  /** The Client ID of your privacy center's OAuth 2 application */
  CLIENT_ID: t.array(t.string),
  /** Configuration related to retrieving the Oauth Token */
  GET_TOKEN: t.intersection([
    t.type({
      /** The token URL for your OAuth API (authorization_code => access_token) e.g. https://api.acme.com/oauth/token */
      URL: t.string,
      /** Configuration for the body of the request that fetches the OAuth token */
      BODY: schemaToCodec(OAuthParametersGetTokenBodySchema),
    }),
    t.partial({
      /** The HTTP Method for fetching the OAUTH Token from your OAuth API */
      METHOD: valuesOf(HttpMethod),
      /** The headers to include when fetching the OAuth token */
      HEADERS: t.record(t.string, t.string),
    }),
  ]),
  /** Configuration related to fetching the core ID for the data subject */
  GET_CORE_ID: schemaToCodec(OAuthParametersGetCoreIdSchemaInput),
  /** Configuration related to fetching the email for the data subject */
  GET_EMAIL: schemaToCodec(OAuthParametersGetEmailSchemaInput),
  /** Configuration related to fetching the data subject profile picture */
  GET_PROFILE_PICTURE: t.union([
    schemaToCodec(OAuthParametersGetProfilePictureSchemaInput),
    t.null,
  ]),
});

/** Override types */
export type PatchOAuthParametersInput = t.TypeOf<
  typeof PatchOAuthParametersInput
>;

export const PatchTenantParamsInput = t.partial({
  /** Supported data subject authentication methods. */
  DATA_SUBJECT_AUTHENTICATION_METHODS: t.array(
    valuesOf(SombraDataSubjectAuthMethod),
  ),
  /** Supported customer employee authentication methods. */
  EMPLOYEE_AUTHENTICATION_METHODS: t.array(valuesOf(SombraEmployeeAuthMethod)),
  /** Allows customer employees to generate and download the data subject's CEK */
  ALLOW_EMPLOYEE_CEK_ACCESS: t.boolean,
  /** Company's data subject authentication via JWT public key */
  JWT_AUTHENTICATION_PUBLIC_KEY: JwtAuthenticationPublicKey,
  /** SAML Configuration, for the employees */
  SAML: t.union([schemaToCodec(SAMLSchemaInput), t.null]),
  /** OAuth configuration, for data subject authentication */
  OAUTH_PARAMETERS: t.union([PatchOAuthParametersInput, t.null]),
  /** Allows unauthenticated data subjects to update preference store. */
  ALLOW_UNAUTHENTICATED_PREFERENCE_UPDATES: t.boolean,
});

/** Override types */
export type PatchTenantParamsInput = t.TypeOf<typeof PatchTenantParamsInput>;

/**
 * Input for patching tenant secrets
 */
export const PatchTenantSecretsInput = t.partial({
  /** The Client Secret of your privacy center's OAuth 2 application */
  OAUTH_SECRETS: t.array(t.string),
});

/** Override types */
export type PatchTenantSecretsInput = t.TypeOf<typeof PatchTenantSecretsInput>;

/** The tenant parameters config, parsed from the header x-sombra-tenant-parameters */
export const TenantParamsInput = t.intersection([
  t.type({
    /**
     * The tenant organization URI.
     */
    ORGANIZATION_URI: NonEmptyString,
    /** Supported data subject authentication methods. */
    DATA_SUBJECT_AUTHENTICATION_METHODS: t.array(
      valuesOf(SombraDataSubjectAuthMethod),
    ),
    /** Supported customer employee authentication methods. */
    EMPLOYEE_AUTHENTICATION_METHODS: t.array(
      valuesOf(SombraEmployeeAuthMethod),
    ),
  }),
  t.partial({
    /** Allows customer employees to generate and download the data subject's CEK */
    ALLOW_EMPLOYEE_CEK_ACCESS: t.boolean,
    /** Company's data subject authentication via JWT public key */
    JWT_AUTHENTICATION_PUBLIC_KEY: t.array(JwtAuthenticationPublicKey),
    /** SAML Configuration, for the employees */
    SAML: schemaToCodec(SAMLSchema),
    /** OAuth configuration, for data subject authentication */
    OAUTH_PARAMETERS: OAuthParametersInput,
    /** Allows unauthenticated data subjects to update preference store. */
    ALLOW_UNAUTHENTICATED_PREFERENCE_UPDATES: t.boolean,
  }),
]);
/** Override types */
export type TenantParamsInput = t.TypeOf<typeof TenantParamsInput>;

/**
 * The coreIdentifier
 */
export const CoreIdentifier = t.type({
  value: t.string,
});

/** Override types */
export type CoreIdentifier = t.TypeOf<typeof CoreIdentifier>;

/**
 * The encrypted data and its associated metadata
 */
export const EncryptedDataPoint = t.intersection([
  t.type({
    /**
     * The blob of encrypted data
     *
     * If this is 'false', then we create a Request File with no data.
     * This is necessary for cases where we might NOT want to upload the
     * file to our own servers directly, but we do want to link to it.
     */
    value: t.union([t.string, t.literal(false)]),
    /** The signature of the encrypted data, used to decrypt */
    signature: t.union([t.string, t.null]),
  }),
  t.partial({
    /** The subdatapoint fields of the object being encrypted */
    subDatapoints: t.array(t.string),
    /** When running locally, return the raw data for debugging purposes */
    debugRawData: t.any,
  }),
]);

/** Override types */
export type EncryptedDataPoint = t.TypeOf<typeof EncryptedDataPoint>;

export const RawDataPointMetadata = t.intersection([
  t.type({
    /** Name of data point */
    name: t.string,
  }),
  t.partial({
    /** Description of data point */
    description: t.string,
    /** the title of the data point */
    title: t.string,
    /** Encryption metadata */
    encryption: valuesOf(TableEncryptionType),
  }),
]);

/** Override types. */
export type RawDataPointMetadata = t.TypeOf<typeof RawDataPointMetadata>;

export const SubDataPointMetadata = t.intersection([
  RawDataPointMetadata,
  t.partial({
    /** Type of the data point */
    dataType: t.string,
    /** If data point is a primary key */
    isPrimaryKey: t.boolean,
    /** Column Encryption metadata */
    encryption: valuesOf(ColumnEncryptionType),
    /**
     * Encryption metadata
     *
     * @deprecated - should use encryption
     * TODO - https://transcend.height.app/T-39709 - remove encryption field
     */
    encryptionType: valuesOf(EncryptionType),
  }),
]);

/** Override types. */
export type SubDataPointMetadata = t.TypeOf<typeof SubDataPointMetadata>;

export const SubDataPointClassificationInput = t.intersection([
  t.type({
    dataSiloId: dbModelId('dataSilo'),
    subDataPointFQId: t.string, // `${dataPointLevels.join('.')}.${dataPoint}.${subDataPoint}`
    /** @deprecated - should use dataPointLevelMetadatas */
    dataPointLevels: t.array(t.string),
    /** @deprecated - should use dataPointMetadata  */
    dataPoint: t.string,
    /** @deprecated - should use subDataPointMetadata */
    subDataPoint: t.string,
    dataType: valuesOf(KnownDataType),
  }),
  t.partial({
    derivedFromSubDataPoint: t.string,
    encryptedSamplesS3Key: t.string,
    encryptedSamplesArray: t.string,
    /** Metadata field to add description to each data level */
    dataPointLevelMetadatas: t.array(RawDataPointMetadata),
    /**  Metadata field to add description to a data point */
    dataPointMetadata: RawDataPointMetadata,
    /**  Metadata field to add description to a sub data point */
    subDataPointMetadata: SubDataPointMetadata,
    error: t.string,
    /** Is error non-retry error */
    nonRetryError: t.boolean,
  }),
]);

/** Override types. */
export type SubDataPointClassificationInput = t.TypeOf<
  typeof SubDataPointClassificationInput
>;

export const EnrichedIdentifier = t.intersection([
  t.type({
    /** The value of this extra identifier */
    value: t.string,
  }),
  t.partial({
    /** optional list of paths an identifier relates to */
    paths: t.array(t.string),
  }),
]);

/** Override types */
export type EnrichedIdentifier = t.TypeOf<typeof EnrichedIdentifier>;

export const EnrichedIdentifiers = t.record(
  t.string,
  t.array(EnrichedIdentifier),
);
/** Override types */
export type EnrichedIdentifiers = t.TypeOf<typeof EnrichedIdentifiers>;

export const SignedIdentifiers = t.array(
  t.type({
    name: t.string,
    jwts: t.array(t.string),
  }),
);

/** Override types */
export type SignedIdentifiers = t.TypeOf<typeof SignedIdentifiers>;

export const RequestIdentifier = t.intersection([
  t.type({
    /** Model ID. */
    id: dbModelId('RequestIdentifier'),
    /** Identifier Value. */
    value: t.string,
    /** Name of the identifier. */
    name: t.string,
    /** Type. */
    type: t.string,
  }),
  t.partial({
    /** Is encrypted. */
    isEncrypted: t.boolean,
  }),
]);

/** Override types. */
export type RequestIdentifier = t.TypeOf<typeof RequestIdentifier>;

export const TransformedEncryptedIdentifiers = t.array(
  t.intersection([
    t.type({
      /** The encrypted identifier */
      identifier: t.string,
      /** The set of mutations to perform, in order. */
      mutators: t.array(t.string),
    }),
    t.union([
      t.type({
        /** The resultant transformed-and-encrypted identifier. */
        transformedIdentifier: t.string,
      }),
      t.type({
        /** The error message, if any. */
        error: t.string,
      }),
    ]),
  ]),
);

/** Override types */
export type TransformedEncryptedIdentifiers = t.TypeOf<
  typeof TransformedEncryptedIdentifiers
>;

/**
 * The auth context for a data subject session
 */
export const DataSubjectAuthContext = t.intersection([
  t.type({
    /** The core identifier for this authenticated user */
    coreIdentifier: t.string,
  }),
  t.partial({
    /** The email value */
    email: t.union([t.string, t.null]),
    /** The initial authentication method, e.g. OAuth */
    initialAuthMethod: valuesOf(SombraDataSubjectAuthMethod),
    /** The profile associated with these identifiers */
    profile: schemaToCodec(SombraProfile),
    /** Whether the email is verified */
    emailIsVerified: t.boolean,
    /** Type of data subject */
    subjectType: t.string,
    /**
     * Examples of extra identifiers:
     * idfa: [{ value: 'EA7583CD-A667-48BC-B806-42ECB2B48606' }]
     * gaid: [{ value: 'cdda802e-fb9c-47ad-9866-0794d394c912' }]
     * email: [{ value: 'another-email-that-is-verified@datasubject.com' }]
     * custom: [{ value: 'mbrook', name: 'username' }]
     */
    attestedExtraIdentifiers: schemaToCodec(DeprecatedRequestIdentifiersInput),
  }),
]);

/** The auth context for a data subject session */
export type DataSubjectAuthContext = t.TypeOf<typeof DataSubjectAuthContext>;

/**
 * Metadata from sombra about a file
 */
export const SignedIdentity = t.partial({
  /** The auth method that attested to the identifier before signed by sombra */
  initialAuthMethod: t.string,
});

/** Type override */
export type SignedIdentity = t.TypeOf<typeof SignedIdentity>;

/**
 * The match dictionary.
 * regex matches (keys) to lists of remoteIds
 */
export const MatchDict = t.record(t.string, t.array(t.string));

/** Type override */
export type MatchDict = t.TypeOf<typeof MatchDict>;

/**
 * A codec to describe the shape of the state parameter passed through vendor
 */
export const StateCodec = t.intersection([
  t.type({
    /**
     * The dhEncrypted data from the connection form.
     *
     * We use this value as our source of randomness in the oauth loop.
     * To initiate the oauth flow, the incoming request but be attached to a valid
     * session so anyone not logged into transcend would be rejected.
     *
     * The dhEncrypted is generated on the frontend, and has a nice security property
     * of allowing us to back out who initiated the login flow.
     */
    dhEncrypted: t.string,
  }),
  t.partial({
    /** The subdomain */
    subdomain: t.string,
    /** The data silo id */
    dataSiloId: dbModelId('dataSilo'),
  }),
]);

/** Type override */
export type StateCodec = t.TypeOf<typeof StateCodec>;

/**
 * The auth context for an employee session
 */
export const EmployeeAuthContext = t.type({
  /**
   * An ID representing the user being logged in.
   * This can be the transcend user.id (UUID)
   * or the SAML nameID (email)
   */
  employeeUserId: t.string,
  /** The email value */
  email: t.string,
});

/** The auth context for an employee session */
export type EmployeeAuthContext = t.TypeOf<typeof EmployeeAuthContext>;

/** Shared body type for webhook */
export const WebhookBase = t.type({
  /** The type of request the data subject (DS) submitted */
  type: t.string, // SHOULD USE RequestAction, but package structure difficult
  /** The kind of user requesting this data */
  dataSubject: t.type({
    /** The type of data subject */
    type: t.string,
  }),
  /** Whether the request is part of a test */
  isTest: t.boolean,
});

/** The attributes for requests */
export const RequestAttribute = t.type({
  key: t.string,
  values: t.array(t.string),
});

/** Type overrides */
export type RequestAttribute = t.TypeOf<typeof RequestAttribute>;

/** The Transcend organization in context for webhook */
export const OrganizationWebhookExtra = t.type({
  /** ID of the organization */
  id: dbModelId('organization'),
  /** Name of the organization */
  name: t.string,
  /** URI of the organization */
  uri: t.string,
});

/** Type overrides */
export type OrganizationWebhookExtra = t.TypeOf<
  typeof OrganizationWebhookExtra
>;

/** Additional webhook metadata related to the request */
export const WebhookExtraBase = t.type({
  /** The request information  */
  request: t.intersection([
    t.type({
      /** The id of the request */
      id: dbModelId('request'),
      /** Link to the request */
      link: t.string,
      /** Extra request details */
      details: t.string,
      /** Time request as made */
      createdAt: t.string,
      /** Language to translate request to */
      locale: valuesOf(LanguageKey),
      /** Origin that the request came from */
      origin: valuesOf(RequestOrigin),
      /** Attributes defined for this request (.e.g [{ key: "Tags", values: ["sensitive", "manual"] }] ) */
      attributes: t.array(RequestAttribute),
    }),
    t.partial({
      /** Country of request */
      country: valuesOf(IsoCountryCode),
      /** Sub division of request */
      countrySubDivision: valuesOf(IsoCountrySubdivisionCode),
    }),
  ]),
  /** The organization information */
  organization: OrganizationWebhookExtra,
});

/** Type overrides */
export type WebhookExtraBase = t.TypeOf<typeof WebhookExtraBase>;

/** The webhook shape for an enricher */
export const EnricherTranscendWebhookNotification = t.intersection([
  WebhookBase,
  t.type({
    /** Additional metadata related to the request */
    extras: t.intersection([
      WebhookExtraBase,
      t.type({
        enricher: t.type({
          /** The ID fo the enricher */
          id: dbModelId('enricher'),
          /** Title of enricher */
          title: t.string,
        }),
        /** The identifier configuration */
        identifier: t.type({
          /** The ID of the identifier */
          id: dbModelId('identifier'),
          /** The unique name of the identifier */
          name: t.string,
          /** The type of identifier */
          type: valuesOf(IdentifierType),
        }),
        /** The ID of the request enricher firing for */
        requestEnricherId: dbModelId('RequestEnricher'),
      }),
    ]),
    /** The request identifier to look up. Could use RequestIdentifierInput but package dep problems */
    requestIdentifier: t.any,
  }),
]);

/** Override as a type */
export type EnricherTranscendWebhookNotification = t.TypeOf<
  typeof EnricherTranscendWebhookNotification
>;

/** The webhook shape for a dataSilo during the COMPILING stage */
export const DataSiloTranscendWebhookNotification = t.intersection([
  WebhookBase,
  t.type({
    /** Additional metadata related to the request */
    extras: t.intersection([
      WebhookExtraBase,
      t.type({
        /** The profile to lookup (that includes the identifier) */
        profile: t.intersection([
          t.type({
            /** The ID fo the enricher */
            id: dbModelId('profile'),
            /** The ID of the related RequestDataSilo */
            RequestDataSiloId: dbModelId('RequestDataSilo'),
            /** The identifier value to lookup */
            identifier: t.string,
          }),
          t.partial({
            /** The type of identifier i.e. email */
            type: t.union([t.string, t.null]),
          }),
        ]),
        /** The data silo configuration being notified */
        dataSilo: t.type({
          /** The ID of the identifier */
          id: dbModelId('dataSilo'),
          /** The title of the data silo */
          title: t.string,
          /** The description of the data silo */
          description: t.string,
          /** Link to the data silo configuration */
          link: t.string,
        }),
      }),
      t.partial({
        /** The purpose change event */
        purpose: PreferenceStorePurposeResponse,
      }),
    ]),
  }),
]);

/** Override as a type */
export type DataSiloTranscendWebhookNotification = t.TypeOf<
  typeof DataSiloTranscendWebhookNotification
>;

/** The webhook shape for when airgap is deployed */
export const AirgapDeploymentWebhookNotification = t.type({
  /** Airgap bundle version */
  version: t.string,
  /** The type of deployment (test or production) */
  bundleType: valuesOf(ConsentBundleType),
  /** Additional metadata related to the request */
  extras: t.intersection([
    t.type({
      /** The organization information */
      organization: OrganizationWebhookExtra,
      /** The airgap bundle being notified */
      airgapBundle: t.type({
        /** The ID of the airgap bundle */
        id: dbModelId('airgapBundle'),
        /** The name of the bundle */
        name: t.string,
      }),
    }),
    t.partial({
      /** The user that made the change */
      user: t.type({
        /** The ID of the identifier */
        id: dbModelId('user'),
        /** The name of the user */
        name: t.string,
        /** The email of the user */
        email: t.string,
      }),
      /** The apiKey that made the change */
      apiKey: t.type({
        /** The ID of the identifier */
        id: dbModelId('apiKey'),
        /** The title of the API key */
        title: t.string,
      }),
    }),
  ]),
});

/** Override as a type */
export type AirgapDeploymentWebhookNotification = t.TypeOf<
  typeof AirgapDeploymentWebhookNotification
>;

/** The webhook shape for a dataSilo manual notification */
export const AvcBulkManualNotification = t.type({
  /** The type of request the data subject (DS) submitted */
  type: valuesOf(RequestActionObjectResolver),
  /** Link to bulk notification */
  completionLink: t.string,
  /** Email template connected to notification */
  template: t.type({
    id: dbModelId('template'),
    title: t.string,
    template: t.string,
    subject: t.string,
  }),
  /** Additional metadata related to the request */
  extras: t.type({
    /** The organization information */
    organization: t.type({
      /** ID of the organization */
      id: dbModelId('organization'),
      /** Name of the organization */
      name: t.string,
      /** URI of the organization */
      uri: t.string,
    }),
    /** The data silo configuration being notified */
    dataSilo: t.type({
      /** The ID of the identifier */
      id: dbModelId('dataSilo'),
      /** The title of the data silo */
      title: t.string,
      /** The description of the data silo */
      description: t.string,
      /** Link to the data silo configuration */
      link: t.string,
    }),
  }),
});

/** Override as a type */
export type AvcBulkManualNotification = t.TypeOf<
  typeof AvcBulkManualNotification
>;

/**
 * The input to the /send-email route, provided by sombra or backend
 */
export const SendEmailInput = t.intersection([
  t.type({
    /** email address to send to */
    to: t.string,
    /** subject of email */
    subject: t.string,
    /** The HTML email content body */
    content: t.string,
  }),
  t.partial({
    /** attachments */
    attachments: t.array(
      t.type({
        filename: t.string,
        content: t.array(t.array(t.string)),
      }),
    ),
    /** stringified email send date */
    date: t.string,
    /** The email preview text */
    text: t.string,
    /** Encrypted template variables to fill the email with */
    templateVariables: t.record(t.string, t.string),
  }),
]);

/** Override type */
export type SendEmailInput = t.TypeOf<typeof SendEmailInput>;

/** Parsed email attachments */
export const EmailAttachment = t.type({
  filename: t.string,
  content: t.string,
  contentType: t.string,
  size: t.number,
});

/** Type override */
export type EmailAttachment = t.TypeOf<typeof EmailAttachment>;

/**
 * The input to the store email JWT signed by sombra
 * Includes encrypted email contents
 */
export const LogEmailReplySombraInput = t.intersection([
  t.type({
    /** email address to send to */
    to: t.string,
    /** subject of email */
    subject: t.string,
    /** The HTML email content body */
    content: t.string,
  }),
  t.partial({
    /** the email attachments */
    attachments: t.array(EmailAttachment),
  }),
]);

/** Override type */
export type LogEmailReplySombraInput = t.TypeOf<
  typeof LogEmailReplySombraInput
>;

/**
 * When sending an email through sombra, the shape of the dh payload
 */
export const SendEmailDhPayload = t.type({
  /** Email template */
  template: t.string,
  /** Email subject */
  subject: t.union([t.string, t.undefined]),
});

/** Override type */
export type SendEmailDhPayload = t.TypeOf<typeof SendEmailDhPayload>;

/** Configuration for how data subjects authenticate with Sombra */
export interface DataSubjectAuth {
  /** Supported customer employee authentication methods. */
  DATA_SUBJECT_AUTHENTICATION_METHODS: SombraDataSubjectAuthMethod[];
  /** Company's data subject authentication via JWT public key */
  JWT_AUTHENTICATION_PUBLIC_KEY?: JwtAuthenticationPublicKey[];
  /** OAuth application parameters */
  OAUTH_PARAMETERS?: OAuthParameters;
  /** OAuth application secrets */
  OAUTH_SECRETS?: OAuthSecrets;
}

/**
 * A database integration query
 */
export const DatabaseIntegrationQuery = t.type({
  query: t.string,
});

/** Override types */
export type DatabaseIntegrationQuery = t.TypeOf<
  typeof DatabaseIntegrationQuery
>;

/**
 * The database integration query payload. The fields need to be kept
 * in sync with the values of `RequestActionObjectResolver`
 */
export const DatabaseIntegrationQueryPayload = t.partial({
  ACCESS: t.string,
  ERASURE: t.string,
  AUTOMATED_DECISION_MAKING_OPT_OUT: t.string,
  CONTACT_OPT_OUT: t.string,
  SALE_OPT_OUT: t.string,
  TRACKING_OPT_OUT: t.string,
  RECTIFICATION: t.string,
  RESTRICTION: t.string,
});

/** Override types */
export type DatabaseIntegrationQueryPayload = t.TypeOf<
  typeof DatabaseIntegrationQueryPayload
>;

/**
 * Ranges that indicate [start, end)
 */
export const CIntervals = t.array(t.tuple([t.number, t.number]));

/** Override types. */
export type CIntervals = t.TypeOf<typeof CIntervals>;

/**
 * A mapping from JSON path to indices that should be redacted.
 *
 * i.e.
 * {
 *  ['$.title']: [[3,5], [12,16]],
 *  ['$.content.value']: [[5,18]],
 * }
 *
 * Indices must be sorted and non-overlapping.
 *
 * valid: [[0, 2], [3, 5], [8, 10]]
 * invalid: [[0, 3], [2, 5]]
 * invalid: [[5, 9], [0, 2]]
 * invalid: [[5, 9], [6, 7]]
 * invalid: [[9, 5], [0, 2]]
 */
export const CRedactIndices = t.record(t.string, CIntervals);

/** Override types. */
export type CRedactIndices = t.TypeOf<typeof CRedactIndices>;

export const SubDataPointWithSamples = t.intersection([
  t.type({
    /** The sub datapoint name */
    subDataPoint: t.string,
    /**
     * the encrypted JSON stringified array of sample values, ready to be
     * placed into S3
     */
    encryptedSamplesArray: t.string,
  }),
  t.partial({
    /** The parent sub data point name */
    derivedFromSubDataPoint: t.string,
  }),
]);

/** Override types. */
export type SubDataPointWithSamples = t.TypeOf<typeof SubDataPointWithSamples>;

export const WrappedKeyWithMetadata = t.type({
  /** the wrapped key */
  wrappedKey: t.string,
  /** the auth tag */
  authTag: t.string,
  /** the iv */
  iv: t.string,
});

/** Override types. */
export type WrappedKeyWithMetadata = t.TypeOf<typeof WrappedKeyWithMetadata>;

export const IdentifierMetadata = t.type({
  /** Type of identifier */
  type: t.string,
});

/** Type override */
export type IdentifierMetadata = t.TypeOf<typeof IdentifierMetadata>;

export const SnowflakeFetchDatabases = t.intersection([
  t.type({
    /** Operation type. */
    operation: t.literal(SnowflakeSchemaQueryOperation.ListDatabases),
  }),
  t.partial({
    /** Limit. */
    limit: t.number,
    /** Last encountered database to use for pagination. */
    lastEncounteredDatabaseName: t.string,
  }),
]);

/** Override types. */
export type SnowflakeFetchDatabases = t.TypeOf<typeof SnowflakeFetchDatabases>;

export const SnowflakeFetchSchemas = t.intersection([
  t.type({
    /** Operation type. */
    operation: t.literal(SnowflakeSchemaQueryOperation.ListSchemas),
    /** Database name. */
    database: t.string,
  }),
  t.partial({
    /** Limit. */
    limit: t.number,
    /** Last encountered schema to use for pagination. */
    lastEncounteredSchemaName: t.string,
  }),
]);

/** Override types. */
export type SnowflakeFetchSchemas = t.TypeOf<typeof SnowflakeFetchSchemas>;

export const SnowflakeFetchDatapoints = t.intersection([
  t.type({
    /** Operation type. */
    operation: t.literal(SnowflakeSchemaQueryOperation.ListTables),
    /** TABLE_CATALOG. */
    database: t.string,
    /** TABLE_SCHEMA. */
    schema: t.string,
  }),
  t.partial({
    /** Limit. */
    limit: t.number,
    /** Last encountered table to use for pagination. */
    lastEncounteredTableName: t.string,
  }),
]);

/** Override types. */
export type SnowflakeFetchDatapoints = t.TypeOf<
  typeof SnowflakeFetchDatapoints
>;

export const SnowflakeFetchSubDatapoints = t.intersection([
  t.type({
    /** Operation type. */
    operation: t.literal(SnowflakeSchemaQueryOperation.ListColumns),
    /** TABLE_CATALOG. */
    database: t.string,
    /** TABLE_SCHEMA. */
    schema: t.string,
    /** TABLE_NAME. */
    table: t.string,
    /** Approach to use to fetch sub-datapoints (columns). */
    approach: valuesOf(FetchSubDatapointsApproach),
  }),
  t.partial({
    /** Limit. */
    limit: t.number,
    /** Offset, used only for offset pagination. */
    offset: t.number,
  }),
]);

/** Override types. */
export type SnowflakeFetchSubDatapoints = t.TypeOf<
  typeof SnowflakeFetchSubDatapoints
>;

export const SnowflakeSchemaQueryResponse = t.union([
  t.type({
    operation: t.literal(SnowflakeSchemaQueryOperation.ListDatabases),
    databases: t.array(RawDataPointMetadata),
  }),
  t.type({
    operation: t.literal(SnowflakeSchemaQueryOperation.ListSchemas),
    schemas: t.array(RawDataPointMetadata),
  }),
  t.type({
    operation: t.literal(SnowflakeSchemaQueryOperation.ListTables),
    tables: t.array(RawDataPointMetadata),
  }),
  t.type({
    operation: t.literal(SnowflakeSchemaQueryOperation.ListColumns),
    columns: t.array(SubDataPointClassificationInput),
  }),
]);

/** Override types. */
export type SnowflakeSchemaQueryResponse = t.TypeOf<
  typeof SnowflakeSchemaQueryResponse
>;

export const RegexFilter = t.type({
  /** The attribute Name for which the regex should be applied to */
  attributeName: t.string,
  /** list of regexes */
  regexList: t.array(t.string),
  /** regexes provided for the attributeName can either be used as INCLUDE/EXCLUDE filter */
  type: valuesOf(RegexFilterActions),
  /** regex function to be applied on the regexList */
  regexFunction: valuesOf(RegexFilterFunctions),
});

/** Override types. */
export type RegexFilter = t.TypeOf<typeof RegexFilter>;

export const BigQueryAPIRequest = t.intersection([
  t.type({
    baseHost: t.string,
    uri: t.type({
      /** SQL query URI */
      query: t.string,
      /** Tables specific URI, can be used to create/delete tables */
      table: t.string,
      /** Job specific URI, used to check whether a job has finished execution */
      job: t.string,
      /** Dataset specific URI, used too create/delete datasets and fetch all datasets */
      dataset: t.string,
    }),
    scopes: t.array(t.string),
  }),
  t.partial({
    /** ID of job */
    jobId: t.string,
    /** Token for pagination */
    pageToken: t.string,
    /** Location of query processing */
    queryLocation: t.string,
    /** Regex filters */
    regexFilters: t.array(RegexFilter),
  }),
]);

/** Override types. */
export type BigQueryAPIRequest = t.TypeOf<typeof BigQueryAPIRequest>;

export const DatabricksLakehouseRequest = t.partial({
  /** The id of the SQL statement we are executing */
  statementId: t.string,
  /** The columns use for formatting results. Contains metadata such as column name and column position */
  columns: t.array(t.record(t.string, t.union([t.string, t.number]))),
  /** The internal link for the next chunk, no more chunks if undefined */
  nextChunkInternalLink: t.string,
});

/** Override types. */
export type DatabricksLakehouseRequest = t.TypeOf<
  typeof DatabricksLakehouseRequest
>;

export const DatabricksLakehouseResponse = t.intersection([
  t.type({
    /** The id of the SQL statement we are executing */
    statementId: t.string,
    /** The status of the job */
    jobStatus: t.string,
  }),
  t.partial({
    /** The columns use for formatting results. Contains metadata such as column name and column position */
    columns: t.array(t.record(t.string, t.union([t.string, t.number]))),
    /** The starting index of the next chunk to request */
    nextChunkInternalLink: t.string,
  }),
]);

/** Override types. */
export type DatabricksLakehouseResponse = t.TypeOf<
  typeof DatabricksLakehouseResponse
>;

export const ProxyTreasureDataRequest = t.type({
  /** The SQL query */
  query: t.string,
  /** The database */
  database: t.string,
  /** The base host API URL to call */
  baseHost: t.string,
});

/** Override types */
export type ProxyTreasureDataRequest = t.TypeOf<
  typeof ProxyTreasureDataRequest
>;

export const ProxyTreasureDataResponse = t.type({
  /** Response body from Treasure Data API */
  encryptedBody: t.any,
  /** Plain text body from Treasure Data API */
  plaintextBody: t.any,
});

/** Override types */
export type ProxyTreasureDataResponse = t.TypeOf<
  typeof ProxyTreasureDataResponse
>;

export const ProxyGoogleCloudSpannerRequest = t.type({
  /** The SQL query */
  query: t.union([t.string, t.null]),
  /** The name of the session */
  sessionName: t.string,
  /** The database name */
  database: t.string,
});

/** Override types. */
export type ProxyGoogleCloudSpannerRequest = t.TypeOf<
  typeof ProxyGoogleCloudSpannerRequest
>;

export const ProxyGoogleCloudSpannerResponse = t.type({
  encryptedBody: t.array(t.any),
});

/** Override types. */
export type ProxyGoogleCloudSpannerResponse = t.TypeOf<
  typeof ProxyGoogleCloudSpannerResponse
>;

export const DatabaseRequestMetadata = t.union([
  t.partial({ bigQuery: BigQueryAPIRequest }),
  t.partial({ databricksLakehouse: DatabricksLakehouseRequest }),
]);

/** Override types. */
export type DatabaseRequestMetadata = t.TypeOf<typeof DatabaseRequestMetadata>;

export const ProxyDatabaseRequestBody = t.intersection([
  t.type({
    query: t.union([t.string, t.null]),
  }),
  t.partial({
    isDataSubjectRequest: t.boolean,
    identifierMetadata: IdentifierMetadata,
    stripPaths: t.array(t.string),
  }),
  DatabaseRequestMetadata,
]);

/** Override types. */
export type ProxyDatabaseRequestBody = t.TypeOf<
  typeof ProxyDatabaseRequestBody
>;

export const DatabaseResponseMetadata = t.union([
  t.partial({ bigQuery: t.type({ jobId: t.string }) }),
  t.partial({ databricksLakehouse: DatabricksLakehouseResponse }),
]);

/** Override types. */
export type DatabaseResponseMetadata = t.TypeOf<
  typeof DatabaseResponseMetadata
>;

export const ProxyDatabaseResponseBody = t.intersection([
  t.type({
    encryptedBody: t.array(t.any),
  }),
  t.partial({
    signedIdentifiers: SignedIdentifiers,
  }),
  DatabaseResponseMetadata,
]);

/** Override types. */
export type ProxyDatabaseResponseBody = t.TypeOf<
  typeof ProxyDatabaseResponseBody
>;

/** SFTP Related Codecs */
export const SftpCommandBody = t.type({
  /** The support commands */
  type: valuesOf(SftpSupportedCommand),
  payload: t.partial({
    /** The file path */
    filePath: t.string,
    /** The file type */
    fileType: valuesOf(SftpSupportedFileType),
    /** The file data */
    fileData: t.array(t.record(t.string, t.string)),
    /** Identifiers */
    identifiers: t.string,
    /** Identifiers types */
    identifierTypes: t.string,
    /** Request ID */
    requestId: t.string,
  }),
});

/** Override types. */
export type SftpCommandBody = t.TypeOf<typeof SftpCommandBody>;

/** Concur Related Codecs */
export const ExecuteCdataOdbcCommandBody = t.type({
  /** The supported commands */
  type: valuesOf(CdataOdbcSupportedCommand),
  payload: t.partial({
    /** The table name */
    tableName: t.string,
    /** The limit */
    limit: t.number,
    /** The offset */
    offset: t.number,
  }),
});

/** Override types. */
export type ExecuteCdataOdbcCommandBody = t.TypeOf<
  typeof ExecuteCdataOdbcCommandBody
>;
export const SmbCommandBody = t.intersection([
  t.type({
    type: valuesOf(SmbSupportedCommand),
  }),
  t.partial({
    payload: t.partial({
      /** The path to execute the command against */
      path: t.string,
    }),
  }),
]);

/** Override types. */
export type SmbCommandBody = t.TypeOf<typeof SmbCommandBody>;

/** SMB Related Codecs */
export const SmbCommandResponseBody = t.partial({
  files: t.array(
    t.type({
      /** The file path */
      path: t.string,
      /** The file */
      name: t.string,
      /** The file type */
      type: t.string,
      /** The file size */
      size: t.number,
    }),
  ),
  /** The path returned */
  path: t.string,
});

/** Override types. */
export type SmbCommandResponseBody = t.TypeOf<typeof SmbCommandResponseBody>;

export const FetchSchemaRequestBody = t.partial({
  /** optional catalog name to narrow search */
  database: t.string,
  /** optional catalog regex to narrow search */
  databaseRegex: t.string,
  /** whether the catalog regex should exclude matches */
  databaseNotRegex: t.boolean,
  /** optional schema name to narrow search */
  schema: t.string,
  /** optional schema regex to narrow search */
  schemaRegex: t.string,
  /** whether the schema regex should exclude matches */
  schemaNotRegex: t.boolean,
  /** optional table name to narrow search */
  dataPoint: t.string,
  /** optional table regex to narrow search */
  dataPointRegex: t.string,
  /** whether the table regex should exclude matches */
  dataPointNotRegex: t.boolean,
  /** optional row limit */
  limit: t.number,
  /** optional row offset (to be used with limit) */
  offset: t.number,
  /** optional for snowflake, start searching from database */
  startFromDatabase: t.string,
  /** Fetch schema operations for Snowflake databases. */
  snowflake: t.union([
    SnowflakeFetchDatabases,
    SnowflakeFetchSchemas,
    SnowflakeFetchDatapoints,
    SnowflakeFetchSubDatapoints,
  ]),
});

/** Override types. */
export type FetchSchemaRequestBody = t.TypeOf<typeof FetchSchemaRequestBody>;

export const EncryptSampleRequestBody = t.intersection([
  t.type({
    dataPointLevels: t.array(t.string),
    dataPoint: t.string,
    // TODO: https://transcend.height.app/T-40710 - replace with subDataPointMetadatas
    // @deprecated
    subDataPoints: t.array(t.string),
  }),
  t.partial({
    subDataPointMetadatas: t.array(SubDataPointMetadata),
    filterNotNull: t.boolean,
    limit: t.number,
  }),
  DatabaseRequestMetadata,
]);

/** Override types. */
export type EncryptSampleRequestBody = t.TypeOf<
  typeof EncryptSampleRequestBody
>;

export const EncryptSampleResponseBody = t.intersection([
  t.type({
    /** Array of subDataPoints with encrypted samples */
    subDataPoints: t.array(SubDataPointWithSamples),
  }),
  DatabaseResponseMetadata,
]);

/** Override types. */
export type EncryptSampleResponseBody = t.TypeOf<
  typeof EncryptSampleResponseBody
>;

export const MongoDBQuery = t.type({
  database: t.string,
  collection: t.string,
  type: valuesOf(MongoSupportedQuery),
  payload: t.partial({
    filter: t.record(t.string, t.unknown),
    query: t.record(t.string, t.unknown),
    option: t.record(t.string, t.unknown),
    limit: t.number,
  }),
});

/** Override types. */
export type MongoDBQuery = t.TypeOf<typeof MongoDBQuery>;

export const MongoDBDatabaseCollectionResponse = t.array(t.string);

/** Override types. */
export type MongoDBDatabaseCollectionResponse = t.TypeOf<
  typeof MongoDBDatabaseCollectionResponse
>;

export const MongoDBSchemaResponse = t.record(t.string, t.array(t.string));

/** Override types. */
export type MongoDBSchemaResponse = t.TypeOf<typeof MongoDBSchemaResponse>;

export const MongoDBSchemaQueryResponse = t.type({
  /** Response containing either database names, collection names, or collection schema */
  queryResponse: t.union([
    /**
     * Used to return database/collection names.
     */
    MongoDBDatabaseCollectionResponse,
    /**
     * Mapping of datapoints to subdatapoints
     */
    MongoDBSchemaResponse,
  ]),
});

/** Override types. */
export type MongoDBSchemaQueryResponse = t.TypeOf<
  typeof MongoDBSchemaQueryResponse
>;

/** AWS Related Codecs */

/**
 * TODO: https://transcend.height.app/T-32126 - update this to so each specific query has its own type
 *
 * Delete Command Input
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-dynamodb/interfaces/deleteitemcommandinput.html
 * Query Command Input
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-dynamodb/interfaces/querycommandinput.html
 * Scan Command Input
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-dynamodb/interfaces/scancommandinput.html
 */
export const DynamoDBQuery = t.type({
  /** The type of DynamoDB command */
  type: valuesOf(DynamoDBSupportedQuery),
  payload: t.partial({
    /** A condition that must be satisfied in order for a conditional DeleteItem to succeed. */
    ConditionExpression: t.string,
    /** Determines the read consistency model. */
    ConsistentRead: t.boolean,
    /** The primary key of the first item that this operation will evaluate. */
    ExclusiveStartKey: t.record(t.string, t.string),
    /** One or more substitution tokens for attribute names in an expression. */
    ExpressionAttributeNames: t.record(t.string, t.string),
    /** One or more values that can be substituted in an expression. */
    ExpressionAttributeValues: t.record(t.string, t.unknown),
    /** Where to start listing from for pagination */
    ExclusiveStartTableName: t.string,
    /** A string that contains conditions that DynamoDB applies after the operation, but before the data is returned. */
    FilterExpression: t.string,
    /** The name of an index to query. */
    IndexName: t.string,
    /** The maximum number of items to evaluate (not necessarily the number of matching items). */
    Limit: t.number,
    /** A map of attribute names to AttributeValue objects, representing the primary key of the item to delete. */
    Key: t.record(t.string, t.unknown),
    /** The condition that specifies the key values for items to be retrieved by the Query action. */
    KeyConditionExpression: t.string,
    /** A string that identifies one or more attributes to retrieve from the specified table or index. */
    ProjectionExpression: t.string,
    /** Specifies the order for index traversal */
    ScanIndexForward: t.boolean,
    /** The attributes to be returned in the result. */
    Select: t.string,
    /** The name of the table containing the requested items */
    TableName: t.string,
  }),
});

/** Override types. */
export type DynamoDBQuery = t.TypeOf<typeof DynamoDBQuery>;

/**
 * AWS commands that requires no additional payload
 */
export const GenericAWSCommand = t.type({
  /** The type of AWS command */
  type: valuesOf(GenericAWSSupportedQuery),
  payload: t.UnknownRecord,
});

/** Override types. */
export type GenericAWSCommand = t.TypeOf<typeof GenericAWSCommand>;

/**
 * AWS commands that requires no additional payload
 */
export const GetBucketLocationCommand = t.type({
  /** The type of AWS command */
  type: t.literal(AWSSupportedQuery.GetBucketLocation),
  payload: t.partial({
    Buckets: t.array(t.string),
  }),
});

/** Override types. */
export type GetBucketLocationCommand = t.TypeOf<
  typeof GetBucketLocationCommand
>;

export const ConnectAWSClientRegion = t.partial({
  /** The region of the AWS client */
  region: t.string,
});

/** Override types. */
export type ConnectAWSClientRegion = t.TypeOf<typeof ConnectAWSClientRegion>;

/** Union type of all the different types of AWS command */
export const AWSQuery = t.intersection([
  ConnectAWSClientRegion,
  t.union([GenericAWSCommand, GetBucketLocationCommand]),
]);

/** Override types. */
export type AWSQuery = t.TypeOf<typeof AWSQuery>;

export const ListTablesResult = t.intersection([
  t.type({
    TableNames: t.array(t.string),
  }),
  t.partial({
    LastEvaluatedTableName: t.string,
  }),
]);

/** Override types. */
export type ListTablesResult = t.TypeOf<typeof ListTablesResult>;

export const DescribeTableResult = t.type({
  AttributeDefinitions: t.array(
    t.intersection([
      t.type({
        AttributeName: t.string,
      }),
      t.partial({
        AttributeType: t.string,
      }),
    ]),
  ),
});

/** Override types. */
export type DescribeTableResult = t.TypeOf<typeof DescribeTableResult>;

export const ExecuteDynamoDBCommandResponse = t.type({
  result: t.union([ListTablesResult, DescribeTableResult]),
});

/** Override types. */
export type ExecuteDynamoDBCommandResponse = t.TypeOf<
  typeof ExecuteDynamoDBCommandResponse
>;

export const ListBucketsResult = t.type({ BucketNames: t.array(t.string) });

/** Override types. */
export type ListBucketsResult = t.TypeOf<typeof ListBucketsResult>;

export const DescribeRDSDBInstancesResult = t.array(
  t.intersection([
    t.type({
      Engine: t.string,
      DBInstanceIdentifier: t.union([t.string, t.undefined]),
      DBInstanceArn: t.union([t.string, t.undefined]),
    }),
    t.partial({
      DBName: t.string,
      Endpoint: t.string,
      Port: t.string,
    }),
  ]),
);

/** Override types. */
export type DescribeRDSDBInstancesResult = t.TypeOf<
  typeof DescribeRDSDBInstancesResult
>;

export const DescribeRedshiftClustersResult = t.array(
  t.type({
    ClusterIdentifier: t.union([t.string, t.undefined]),
    DBName: t.union([t.string, t.undefined]),
    Endpoint: t.type({
      Address: t.union([t.string, t.undefined]),
      Port: t.union([t.number, t.undefined]),
    }),
  }),
);

/** Override types. */
export type DescribeRedshiftClustersResult = t.TypeOf<
  typeof DescribeRedshiftClustersResult
>;

export const BucketLocation = t.type({
  bucketName: t.string,
  location: t.string,
});

/** Override types. */
export type BucketLocation = t.TypeOf<typeof BucketLocation>;

export const GetBucketLocationResult = t.array(BucketLocation);

/** Override types. */
export type GetBucketLocationResult = t.TypeOf<typeof GetBucketLocationResult>;

/** Command for getting Redshift cluster's databases */
export const ListRedshiftDatabasesRequest = t.type({
  type: t.literal(AWSSupportedQuery.ListRedshiftDatabases),
  payload: t.intersection([
    t.type({
      Database: t.string,
    }),
    t.partial({
      ClusterIdentifier: t.string,
      DbUser: t.string,
      NextToken: t.string,
      MaxResults: t.number,
    }),
  ]),
});

/** Override types. */
export type ListRedshiftDatabasesRequest = t.TypeOf<
  typeof ListRedshiftDatabasesRequest
>;

export const ListRedshiftDatabasesResult = t.type({
  Databases: t.array(t.string),
  NextToken: t.union([t.undefined, t.string]),
});

/** Override types. */
export type ListRedshiftDatabasesResult = t.TypeOf<
  typeof ListRedshiftDatabasesResult
>;

/**
 * Command for getting AWS Organizations ListAccounts
 */
export const AWSOrganizationsListAccountsCommand = t.type({
  type: t.literal(AWSSupportedQuery.ListAccounts),
  payload: t.partial({
    NextToken: t.string,
    MaxResults: t.number,
  }),
});

/** Override types. */
export type AWSOrganizationsListAccountsCommand = t.TypeOf<
  typeof AWSOrganizationsListAccountsCommand
>;

/**
 * Command for getting AWS Organizations ListAccountsFromParentCommand
 */
export const AWSOrganizationsListAccountsForParentCommand = t.type({
  type: t.literal(AWSSupportedQuery.ListAccountsForParent),
  payload: t.intersection([
    t.type({
      ParentId: t.string,
    }),
    t.partial({
      NextToken: t.string,
      MaxResults: t.number,
    }),
  ]),
});

/** Override types. */
export type AWSOrganizationsListAccountsForParentCommand = t.TypeOf<
  typeof AWSOrganizationsListAccountsForParentCommand
>;

export const AWSOrganizationsListAccountsResult = t.type({
  Accounts: t.array(
    t.type({
      Arn: t.string,
      Id: t.string,
      Name: t.string,
    }),
  ),
  NextToken: t.union([t.undefined, t.string]),
});

/** Override types. */
export type AWSOrganizationsListAccountsResult = t.TypeOf<
  typeof AWSOrganizationsListAccountsResult
>;

export const ExecuteAWSCommandResponse = t.type({
  result: t.union([
    ListTablesResult,
    ListBucketsResult,
    DescribeRDSDBInstancesResult,
    DescribeRedshiftClustersResult,
    GetBucketLocationResult,
    AWSOrganizationsListAccountsResult,
    ListRedshiftDatabasesResult,
  ]),
});

/** Override types. */
export type ExecuteAWSCommandResponse = t.TypeOf<
  typeof ExecuteAWSCommandResponse
>;

export const AmazonS3Query = t.type({
  /** The type of the Amazon S3 command */
  type: valuesOf(AmazonS3SupportedQuery),
  payload: t.partial({
    /** The bucket name containing the object. */
    Bucket: t.string,
    /** Key of the object to get. */
    Key: t.string,
    /** The range of bytes to be downloaded from the object. */
    Range: t.string,
    /** The object key from which to start listing more objects */
    Marker: t.string,
    /** The extensions of keys whose type the client wants */
    SupportedExtensions: t.array(t.string),
    /** The regexes to match when scanning for object keys */
    SupportedRegexes: t.array(t.string),
    /** The maximum amount of keys to return during a listObjects query */
    MaxKeys: t.number,
    /** Prefix on what to query */
    Prefix: t.string,
    /** Delimiter to split result on */
    Delimiter: t.string,
  }),
});

/** Override types. */
export type AmazonS3Query = t.TypeOf<typeof AmazonS3Query>;

/** End of AWS Related Codecs */

/** BigQuery Related Codecs */
export const ListBigQueryDatasetsParameters = t.type({
  type: t.literal(SupportedBigQueryAPI.ListDatasets),
  payload: t.type({
    limit: t.number,
  }),
});

/** Override types. */
export type ListBigQueryDatasetsParameters = t.TypeOf<
  typeof ListBigQueryDatasetsParameters
>;

export const ListBigQueryTablesParameters = t.type({
  type: t.literal(SupportedBigQueryAPI.ListTables),
  payload: t.type({
    datasetId: t.string,
    limit: t.number,
  }),
});

/** Override types. */
export type ListBigQueryTablesParameters = t.TypeOf<
  typeof ListBigQueryTablesParameters
>;

/**
 * BigQuery Related Codecs
 *
 * @deprecated Use BigQueryItem instead
 */
export const BigQuerySchemaName = t.type({
  /** Name of dataset */
  name: t.string,
  /** Location of the dataset */
  location: t.string,
});

/** Override types. */
export type BigQuerySchemaName = t.TypeOf<typeof BigQuerySchemaName>;

/** BigQuery Related Codecs */
export const BigQueryItem = t.type({
  /** Name of dataset */
  name: t.string,
});

/** Override types. */
export type BigQueryItem = t.TypeOf<typeof BigQueryItem>;

export const ListBigQueryColumnsParameters = t.type({
  type: t.literal(SupportedBigQueryAPI.ListColumns),
  payload: t.type({
    datasetId: t.string,
    tableId: t.string,
  }),
});

/** Override types. */
export type ListBigQueryColumnsParameters = t.TypeOf<
  typeof ListBigQueryTablesParameters
>;

/** Union type of all the different types of AWS command */
export const BigQueryAPIQuery = t.intersection([
  t.type({
    bigQuery: BigQueryAPIRequest,
  }),
  t.union([
    ListBigQueryDatasetsParameters,
    ListBigQueryTablesParameters,
    ListBigQueryColumnsParameters,
  ]),
]);

/** Override types. */
export type BigQueryAPIQuery = t.TypeOf<typeof BigQueryAPIQuery>;

export const BigQueryListDatasetsResult = t.intersection([
  t.type({
    /** Datasets */
    datasets: t.array(
      t.intersection([BigQueryItem, t.type({ location: t.string })]),
    ),
  }),
  t.partial({
    /** Page token for pagination */
    pageToken: t.string,
  }),
]);

/** Override types. */
export type BigQueryListDatasetsResult = t.TypeOf<
  typeof BigQueryListDatasetsResult
>;

export const BigQueryListTablesResult = t.intersection([
  t.type({
    tables: t.array(
      t.intersection([
        BigQueryItem,
        t.type({
          /** Type of table */
          type: t.string,
          /** Kind of table */
          kind: t.string,
        }),
      ]),
    ),
  }),
  t.partial({
    /** Page token for pagination */
    pageToken: t.string,
  }),
]);

/** Override types. */
export type BigQueryListTablesResult = t.TypeOf<
  typeof BigQueryListTablesResult
>;

export const BigQueryListTableColumnsResult = t.intersection([
  t.type({
    columns: t.array(
      t.intersection([BigQueryItem, t.type({ type: t.string })]),
    ),
  }),
  t.partial({}),
]);

/** Override types. */
export type BigQueryListTableColumnsResult = t.TypeOf<
  typeof BigQueryListTableColumnsResult
>;

export const ExecuteBigQueryCommandResponse = t.type({
  result: t.union([
    BigQueryListDatasetsResult,
    BigQueryListTablesResult,
    BigQueryListTableColumnsResult,
  ]),
});

/** Override types. */
export type ExecuteBigQueryCommandResponse = t.TypeOf<
  typeof ExecuteBigQueryCommandResponse
>;
/** End of BigQuery Related Codecs */

export const ExecuteSftpCommandResponse = t.partial({
  result: t.any,
  error: t.string,
});

/** Override types. */
export type ExecuteSftpCommandResponse = t.TypeOf<
  typeof ExecuteSftpCommandResponse
>;

export const ExecuteCdataOdbcCommandResponse = t.type({
  result: t.partial({
    tables: t.array(t.string),
    columns: t.array(t.string),
    offset: t.number,
  }),
});

/** Override types. */
export type ExecuteCdataOdbcCommandResponse = t.TypeOf<
  typeof ExecuteCdataOdbcCommandResponse
>;

export const FetchAzureContainersQuery = t.partial({
  containerPrefix: t.string,
  maxPageSize: t.number,
  continuationToken: t.string,
});

/** Override types. */
export type FetchAzureContainersQuery = t.TypeOf<
  typeof FetchAzureContainersQuery
>;

export const FetchAzureBlobsQuery = t.intersection([
  t.type({
    /** https://learn.microsoft.com/en-us/javascript/api/%40azure/storage-blob/containerclient#properties */
    containerName: t.string,
    /** Max page size for pagination */
    maxPageSize: t.number,
  }),
  t.partial({
    /** https://learn.microsoft.com/en-us/javascript/api/%40azure/storage-blob/containerlistblobsoptions#properties  */
    blobPrefix: t.string,
    continuationToken: t.string,
  }),
]);

/** Override types. */
export type FetchAzureBlobsQuery = t.TypeOf<typeof FetchAzureBlobsQuery>;

export const AzureBlobStorageQuery = t.type({
  /** The type of the Azure command */
  type: valuesOf(AzureBlobServiceSupportedQuery),
  payload: t.union([FetchAzureContainersQuery, FetchAzureBlobsQuery]),
});

/** Override types. */
export type AzureBlobStorageQuery = t.TypeOf<typeof AzureBlobStorageQuery>;

export const FetchSchemaResponseBody = t.intersection([
  t.type({
    subDataPointSchemata: t.array(SubDataPointClassificationInput),
  }),
  t.partial({
    /** If operating on a Snowflake database, we get back only what we asked for. */
    snowflake: SnowflakeSchemaQueryResponse,
    bigQuery: t.partial({
      /** Job ID for polling */
      jobId: t.string,
      /** Job completion status */
      jobComplete: t.boolean,
      /** Page Token for cursor pagination */
      pageToken: t.string,
    }),
  }),
]);

/** Override types. */
export type FetchSchemaResponseBody = t.TypeOf<typeof FetchSchemaResponseBody>;

/** Type of incoming CEK used for encrypted values. */
export const SombraProxyRequestEncryptionKeyType = t.union([
  t.literal('DSR_CEK'),
  t.literal('ORG_CEK'),
]);

/** Override types. */
export type SombraProxyRequestEncryptionKeyType = t.TypeOf<
  typeof SombraProxyRequestEncryptionKeyType
>;

export const SombraProxyRequestEncryptedIdentifier = t.type({
  /** Encrypted identifier value. */
  encryptedIdentifier: t.string,
  /** Template value to replace in the request. */
  templateKey: t.string,
});

/** Override types. */
export type SombraProxyRequestEncryptedIdentifier = t.TypeOf<
  typeof SombraProxyRequestEncryptedIdentifier
>;

export const SombraProxyRequestDecryptedIdentifier = t.intersection([
  SombraProxyRequestEncryptedIdentifier,
  t.type({
    /** The decrypted identifier. */
    decryptedIdentifier: t.string,
  }),
]);

/** Override types. */
export type SombraProxyRequestDecryptedIdentifier = t.TypeOf<
  typeof SombraProxyRequestDecryptedIdentifier
>;

export const ProxyRequestEncryptedFields = t.record(t.string, t.array(t.any));

/** Override types. */
export type ProxyRequestEncryptedFields = t.TypeOf<
  typeof ProxyRequestEncryptedFields
>;

export const AwsConnectionString = t.type({
  plaintextContext: t.partial({
    /** Aws region */
    region: t.string,
    /** Aws Account ID */
    accountId: t.string,
    /** Aws role */
    role: t.string,
    /** Aws Account ID for a self-hosted Sombra */
    selfHostedSombraAccountId: t.string,
    /** Aws Role for a self-hosted Sombra */
    selfHostedSombraRole: t.string,
  }),
  /** External ID */
  externalId: t.string,
});

/** Override types. */
export type AwsConnectionString = t.TypeOf<typeof AwsConnectionString>;

export const AzureSecretMap = t.intersection([
  t.type({
    /** Storage Account Name */
    accountName: t.string,
    /** Account Access Key */
    accountKey: t.string,
  }),
  t.partial({
    /** default to https */
    defaultEndpointsProtocol: t.string,
    /** core.windows.net by default */
    endpointSuffix: t.string,
  }),
]);

/** Override types. */
export type AzureSecretMap = t.TypeOf<typeof AzureSecretMap>;

export const SftpSecretMap = t.intersection([
  t.type({
    /** The username */
    username: t.string,
    /** The password */
    password: t.string,
    /** The server/host name */
    host: t.string,
  }),
  t.partial({
    /** The port */
    port: t.string,
  }),
]);

/** Override types. */
export type SftpSecretMap = t.TypeOf<typeof SftpSecretMap>;

export const OdbcSecretMap = t.type({
  /** The client ID */
  clientId: t.string,
  /** The client secret */
  clientSecret: t.string,
});

/** Override types. */
export type OdbcSecretMap = t.TypeOf<typeof OdbcSecretMap>;

export const SmbSecretMap = t.intersection([
  t.type({
    /** The address */
    address: t.string,
  }),
  t.partial({
    /** The username */
    username: t.string,
    /** The password */
    password: t.string,
    /** The domain */
    domain: t.string,
    /** The max protocol */
    maxProtocol: t.string,
  }),
]);

/** Override types. */
export type SmbSecretMap = t.TypeOf<typeof SmbSecretMap>;

export const RequestFileUnzipStreamMetadata = t.type({
  /** Information about the file's type */
  fileType: t.type({
    /** The mimetype of the file */
    mime: t.string,
    /** The file extension */
    ext: t.union([t.string, t.null]),
  }),
  /** An ID used to identify a the HTTP request sent to Sombra associated with a request file download. */
  oneTimeId: t.string,
  /** The file's name */
  fileName: t.string,
});

/** Override types. */
export type RequestFileUnzipStreamMetadata = t.TypeOf<
  typeof RequestFileUnzipStreamMetadata
>;

export const SQLParameter = t.type({
  /** Value of parameter */
  value: t.union([t.string, t.number]),
  /** Type of parameter */
  type: valuesOf(SQLQueryParameterType),
});

/** Type override */
export type SQLParameter = t.TypeOf<typeof SQLParameter>;

export const GotErrorMessage = t.type({
  error: t.partial({
    code: t.union([t.number, t.string]),
    message: t.string,
  }),
});

/** Type override */
export type GotErrorMessage = t.TypeOf<typeof GotErrorMessage>;

/** Fetch schema file types result */
export const FetchSchemaFileType = t.type({
  subDataPointSchemata: t.array(SubDataPointClassificationInput),
});

/** Type override */
export type FetchSchemaFileType = t.TypeOf<typeof FetchSchemaFileType>;

export const EncryptedSampleS3FileTypeRequest = t.intersection([
  t.type({
    bucketName: t.string,
    dataPoint: t.string,
    subDataPoints: t.array(t.string),
  }),
  t.partial({
    subDataPointMetadatas: t.array(SubDataPointMetadata),
    /** Subdatapoints previously fetched with null values */
    subDataPointRefetches: t.array(t.string),
    limit: t.number,
  }),
]);

/**
 * Type override
 */
export type EncryptedSampleS3FileTypeRequest = t.TypeOf<
  typeof EncryptedSampleS3FileTypeRequest
>;

export const EncryptedSampleGCSFileTypeRequest = t.intersection([
  t.type({
    dataPoint: t.string,
    subDataPoints: t.array(t.string),
  }),
  t.partial({
    /** Subdatapoints previously fetched with null values */
    subDataPointRefetches: t.array(t.string),
    limit: t.number,
  }),
]);

/**
 * Type override
 */
export type EncryptedSampleGCSFileTypeRequest = t.TypeOf<
  typeof EncryptedSampleGCSFileTypeRequest
>;

/**
 * Encrypted sample S3 file types result
 */
export const EncryptedSampleResponse = t.intersection([
  t.type({
    /** Array of subDataPoints with encrypted samples */
    subDataPoints: t.array(SubDataPointWithSamples),
  }),
  t.partial({
    /** Array of refetched subDataPoints with encrypted samples */
    subDataPointRefetches: t.array(SubDataPointWithSamples),
  }),
]);

/** Type override */
export type EncryptedSampleResponse = t.TypeOf<typeof EncryptedSampleResponse>;

export const FetchAmazonS3BucketsResponse = t.type({
  bucketNames: t.array(t.string),
});

/** Type override */
export type FetchAmazonS3BucketsResponse = t.TypeOf<
  typeof FetchAmazonS3BucketsResponse
>;

export const FetchRedisKeysResponse = t.intersection([
  t.type({
    result: t.array(
      t.type({
        /** The key */
        key: t.string,
        /** The type */
        type: t.string,
      }),
    ),
  }),
  t.partial({
    /** The cursor for pagination. */
    cursor: t.string,
  }),
]);

/** Type override */
export type FetchRedisKeysResponse = t.TypeOf<typeof FetchRedisKeysResponse>;

export const FetchSchemaAmazonS3Response = t.intersection([
  t.type({
    /** Object keys returned */
    objectKeys: t.array(t.string),
  }),
  t.partial({
    /** Object prefixes, when delimiter is used (for traversing each level in the S3 hierarchy) */
    objectPrefixes: t.array(t.string),
    /** Next file to start search from for pagination */
    nextMarker: t.string,
  }),
]);

/** Type override */
export type FetchSchemaAmazonS3Response = t.TypeOf<
  typeof FetchSchemaAmazonS3Response
>;

export const FetchFileMetadataResponse = t.partial({
  /** Size of file */
  fileSize: t.number,
  /** Content type */
  contentType: t.string,
});

/** Type override */
export type FetchFileMetadataResponse = t.TypeOf<
  typeof FetchFileMetadataResponse
>;

export const FetchAzureContainersResponse = t.intersection([
  /** https://learn.microsoft.com/en-us/javascript/api/%40azure/storage-blob/containeritem */
  t.type({
    containers: t.array(t.type({ containerName: t.string })),
  }),
  t.partial({
    continuationToken: t.string,
    error: t.string,
  }),
]);

/** Type override */
export type FetchAzureContainersResponse = t.TypeOf<
  typeof FetchAzureContainersResponse
>;

export const FetchAzureBlobsResponse = t.intersection([
  t.type({
    blobs: t.array(
      t.intersection([
        t.type({
          /** https://learn.microsoft.com/en-us/javascript/api/%40azure/storage-blob/blobitem#@azure-storage-blob-blobitem-name */
          blobName: t.string,
        }),
        /** https://learn.microsoft.com/en-us/javascript/api/%40azure/storage-blob/blobproperties */
        FetchFileMetadataResponse,
      ]),
    ),
  }),
  t.partial({
    continuationToken: t.string,
    error: t.string,
  }),
]);

/** Type override */
export type FetchAzureBlobsResponse = t.TypeOf<typeof FetchAzureBlobsResponse>;

export const ClassifyTextRequest = t.type({
  /** The text to classify */
  text: t.string,
});

/** Type override */
export type ClassifyTextRequest = t.TypeOf<typeof ClassifyTextRequest>;

export const ClassifyTextRequestWithLookBehind = t.intersection([
  ClassifyTextRequest,
  t.partial({
    /** The text to classify */
    lookBehind: t.union([t.string, t.null]),
  }),
]);

/** Type override */
export type ClassifyTextRequestWithLookBehind = t.TypeOf<
  typeof ClassifyTextRequestWithLookBehind
>;

export const DataSubCategoryForSombra = t.intersection([
  t.type({
    id: dbModelId('dataSubCategory'),
    name: t.string,
    regex: t.union([t.string, t.null]),
    description: t.string,
  }),
  t.partial({
    /** If true, then this category can be passed to the LLM as a label */
    isLLMCategory: t.boolean,
    /** Parent category */
    category: t.string,
  }),
]);

/** Override type. */
export type DataSubCategoryForSombra = t.TypeOf<
  typeof DataSubCategoryForSombra
>;

export const DataSubCategoriesForSombra = t.array(DataSubCategoryForSombra);

/** Override type. */
export type DataSubCategoriesForSombra = t.TypeOf<
  typeof DataSubCategoriesForSombra
>;

export const IdentifierRegex = t.type({
  /** The identifier type */
  type: t.string,
  /** The identifier regex */
  regex: t.string,
});

/** Override type. */
export type IdentifierRegex = t.TypeOf<typeof IdentifierRegex>;

export const IdentifierRegexes = t.record(t.string, IdentifierRegex);

/** Override type. */
export type IdentifierRegexes = t.TypeOf<typeof IdentifierRegexes>;

export const FullTextCategoryPreview = t.type({
  /** Value of classification */
  value: t.string,
  /** Snippet from classification */
  snippet: t.string,
});

/** Override type. */
export type FullTextCategoryPreview = t.TypeOf<typeof FullTextCategoryPreview>;

export const FullTextCategory = t.intersection([
  t.type({
    /** Category of classification */
    category: DataSubCategoryForSombra,
  }),
  FullTextCategoryPreview,
  t.partial({
    /** Confidence of classification */
    confidence: t.number,
  }),
]);

/** Override type. */
export type FullTextCategory = t.TypeOf<typeof FullTextCategory>;

export const ClassifyTextResult = t.type({
  /** Result of classification */
  result: t.array(FullTextCategory),
  /** How long classification took */
  timingMs: t.number,
  /** Input length of the text in bytes */
  inputBytesLength: t.number,
  /** Output length of the text in bytes */
  outputBytesLength: t.number,
});

/** Override type. */
export type ClassifyTextResult = t.TypeOf<typeof ClassifyTextResult>;

export const EncryptedClassifyTextResult = t.intersection([
  t.type({
    /** The value that was classified, encrypted for your security, by Sombra. */
    encryptedValue: EncryptedDataPoint,
    /** Category Id. */
    categoryId: t.string,
    /** The context snippet (associated with the value that was classified), encrypted for your security, by Sombra. */
    encryptedSnippet: EncryptedDataPoint,
    /** The method that identified this unstructured subDataPoint entity */
    classificationMethod: valuesOf(UnstructuredClassificationMethod),
  }),
  t.partial({
    /** The confidence score for this recommendation */
    confidence: t.number,
    /** The version of the classifier that generated the recommendation */
    classifierVersion: t.number,
  }),
]);

/** Override type. */
export type EncryptedClassifyTextResult = t.TypeOf<
  typeof EncryptedClassifyTextResult
>;

export const EncryptedClassifierInput = t.partial({
  /** Datapoint name in plain text */
  name: t.string,
  /** Description of dataPoint  */
  description: t.string,
  /** Encrypted sample data point */
  encryptedSample: t.union([t.array(t.string), t.string, t.null]),
});

/** Override type. */
export type EncryptedClassifierInput = t.TypeOf<
  typeof EncryptedClassifierInput
>;

export const DecryptConsentIdentifierOptions = t.type({
  identifiers: t.array(t.string),
});

/** Override type. */
export type DecryptConsentIdentifierOptions = t.TypeOf<
  typeof DecryptConsentIdentifierOptions
>;

/**
 * This is the underlying type of the encrypted "wrappedKey" response that sombra returns
 */
export const DecryptedConsentIdentifiersResponse = t.type({
  decryptedMapping: t.record(t.string, t.string),
  decryptionErrors: t.array(t.string),
});

/** Override type. */
export type DecryptedConsentIdentifiersResponse = t.TypeOf<
  typeof DecryptedConsentIdentifiersResponse
>;

export const EncryptConsentIdentifierOptions = t.partial({
  /**
   * The identifiers to encrypt. Use DhChannel to pass identifiers
   *
   * TODO: https://transcend.height.app/T-44001 - Clean this up
   */
  identifiers: t.array(t.string),
});

/** Override type. */
export type EncryptConsentIdentifierOptions = t.TypeOf<
  typeof EncryptConsentIdentifierOptions
>;

export const EncryptConsentIdentifiersResponse = t.partial({
  /** Validated and Normalized identifiers */
  normalizedIdentifiers: t.array(t.array(PreferenceStoreIdentifier)),
  /**
   * Encrypted identifiers
   *
   * TODO: https://transcend.height.app/T-44001 - Clean this up
   */
  identifiers: t.array(t.string),
});

/** Override type. */
export type EncryptConsentIdentifiersResponse = t.TypeOf<
  typeof EncryptConsentIdentifiersResponse
>;

export const FetchAndChunkFileFromS3Body = t.intersection([
  t.type({
    /** S3 Object key. */
    objectKey: t.string,
    /** S3 Bucket. */
    bucket: t.string,
    /** S3 File type */
    fileType: t.string,
    /** S3 File size */
    fileSize: t.number,
    /** Request Headers. */
    headers: t.record(t.string, t.string),
  }),
  t.partial({
    /** Max number of bytes to read. */
    maxBytesToRead: t.number,
    /** Timeout for streaming request, in milliseconds. */
    streamingTimeoutMs: t.number,
  }),
]);

/** Override type. */
export type FetchAndChunkFileFromS3Body = t.TypeOf<
  typeof FetchAndChunkFileFromS3Body
>;

export const FetchAndChunkFileFromAzureBody = t.intersection([
  t.type({
    /** Azure storage account container name. */
    containerName: t.string,
    /** Azure blob name. */
    blobName: t.string,
    /** Azure blob File size */
    fileSize: t.number,
    /** Azure blob File type */
    fileType: t.string,
  }),
  t.partial({
    /** Max number of bytes to read. */
    maxBytesToRead: t.number,
    /** Timeout for streaming request, in milliseconds. */
    streamingTimeoutMs: t.number,
  }),
]);

/** Override type. */
export type FetchAndChunkFileFromAzureBody = t.TypeOf<
  typeof FetchAndChunkFileFromAzureBody
>;

export const FetchAndChunkFileFromSmbBody = t.intersection([
  t.type({
    /** The path of the file. */
    path: t.string,
    /** File size */
    fileSize: t.number,
    /** File type */
    fileType: t.string,
  }),
  t.partial({
    /** Max number of bytes to read. */
    maxBytesToRead: t.number,
    /** Timeout for streaming request, in milliseconds. */
    streamingTimeoutMs: t.number,
  }),
]);

/** Override type. */
export type FetchAndChunkFileFromSmbBody = t.TypeOf<
  typeof FetchAndChunkFileFromSmbBody
>;

export const FetchAndChunkTextFileBody = t.intersection([
  t.type({
    /** Text file URL. */
    fileUrl: t.string,
    /** Request Headers. */
    headers: t.record(t.string, t.string),
  }),
  t.partial({
    /** Max number of bytes to read. */
    maxBytesToRead: t.number,
    /** Timeout for streaming request, in milliseconds. */
    streamingTimeoutMs: t.number,
    /** MimeType of content */
    contentMimeType: t.string,
  }),
]);

/** Override type. */
export type FetchAndChunkTextFileBody = t.TypeOf<
  typeof FetchAndChunkTextFileBody
>;

export const FetchAndChunkTextFileResponse = t.type({
  /** The chunks that are going to be used for classification. */
  chunksWithLookBehind: t.array(
    t.type({
      /** Encrypted Chunk of text. */
      encryptedChunk: EncryptedDataPoint,
      /** Encrypted Look behind chunk. */
      encryptedLookBehind: t.union([EncryptedDataPoint, t.null]),
    }),
  ),
  /** Number of chunks that we've read. */
  totalChunksRead: t.number,
  /** Number of bytes we've read. */
  totalBytesRead: t.number,
});

/** Override type. */
export type FetchAndChunkTextFileResponse = t.TypeOf<
  typeof FetchAndChunkTextFileResponse
>;

export const SombraOptions = t.type({
  /** Options for Sombra operations only. */
  sombraOptions: t.intersection([
    t.type({
      /** Data silo SaaS context. */
      saaSContext: t.string,
    }),
    t.partial({
      /** Type of encryption to use on the response. */
      responseEncryptionKeyType: SombraProxyRequestEncryptionKeyType,
      /** Mutate params string. */
      mutateParams: t.string,
      /** Encrypted identifiers for request. */
      encryptedIdentifiers: t.array(SombraProxyRequestEncryptedIdentifier),
      /** Plaintext paths to pull from the response from the remote API. */
      plaintextPaths: t.string,
      /** Context paths to pull from the response from the remote API. */
      contextPaths: t.string,
      /** Paths to strip from the response from the remote API. */
      stripPaths: t.string,
      /**
       * Paths that should be signed as identifiers.
       * This is used for enrichment via integrations.
       * Eventually these identifiers should be encrypted
       * and this field should be removed
       * TODO: https://transcend.height.app/T-1793
       */
      signedIdentifierPaths: t.string,
      /** Paths to compose a checksum from. */
      checksumPaths: t.string,
      /** Path to embedded JSON array, to return as response. */
      embeddedPath: t.string,
      /** Paths to return as encrypted entity from the response from the remote API. */
      encryptedPaths: t.string,
      /** DSR CEK context. */
      dsrCekContext: t.string,
    }),
  ]),
});

/** Override type. */
export type SombraOptions = t.TypeOf<typeof SombraOptions>;

export const IdentifierMapping = t.array(
  t.type({
    existingIdentifiers: t.array(RequestIdentifier),
    enrichedIdentifier: t.type({
      value: t.string,
      path: t.string,
    }),
    mutators: t.array(t.string),
  }),
);

/** Override type. */
export type IdentifierMapping = t.TypeOf<typeof IdentifierMapping>;

export const FailingIdentifiers = t.union([
  t.undefined,
  t.array(
    t.type({
      expectedPath: t.string,
      identifierNames: t.array(t.string),
    }),
  ),
]);

/** Override type. */
export type FailingIdentifiers = t.TypeOf<typeof FailingIdentifiers>;

export const GenerateCEKsResponse = t.array(
  t.type({
    /** The generated CEK */
    encryptedCEKContext: t.string,
    /** The plain text core identifier */
    coreIdentifier: t.string,
    /** The associated profile */
    profile: t.string,
    /**
     * The request identifiers the data subject is claiming are their own.
     */
    requestIdentifiers: t.record(
      t.string,
      t.array(
        t.intersection([
          t.type({
            /** The plaintext value of the identifier */
            value: t.string,
          }),
          t.partial({
            /** The initial auth method used for this identifier */
            initialAuthMethod: t.string,
          }),
        ]),
      ),
    ),
    /** The signed request identifiers */
    signedRequestIdentifiers: partialRecord(
      /** The built in identification strategies like coreIdentifier, email */
      valuesOf(IdentifierType),
      /** The signed identifiers */
      t.array(t.type({ jwt: t.string })),
    ),
  }),
);

/** Override type. */
export type GenerateCEKsResponse = t.TypeOf<typeof GenerateCEKsResponse>;

export const GenerateCEKRequest = t.array(
  t.type({
    /** the data subject request identifier */
    requestId: dbModelId('request'),
    /** The dhEncrypted data */
    dhEncrypted: t.string,
  }),
);

/** Override type. */
export type GenerateCEKRequest = t.TypeOf<typeof GenerateCEKRequest>;

export const GenerateCEKFromConsentIdentifierRequest = t.intersection([
  t.type({
    /** the data subject request identifier */
    requestId: t.string,
    /** The encrypted consent identifier */
    consentIdentifier: t.string,
    /** The subject type */
    subjectType: t.string,
  }),
  t.partial({
    /** When true, this sets `emailIsVerified=false` within the request CEK context */
    requireEmailVerification: t.boolean,
    /** Identifiers associated with the consent identifier */
    identifiers: t.array(PreferenceStoreIdentifier),
  }),
]);

/** Override type. */
export type GenerateCEKFromConsentIdentifierRequest = t.TypeOf<
  typeof GenerateCEKFromConsentIdentifierRequest
>;

/**
 * Generate CEKs from consent identifiers request
 */
export const GenerateCEKFromConsentIdentifiersRequest = t.array(
  GenerateCEKFromConsentIdentifierRequest,
);

/** Override type. */
export type GenerateCEKFromConsentIdentifiersRequest = t.TypeOf<
  typeof GenerateCEKFromConsentIdentifiersRequest
>;

/**
 * Consent Purpose without preference metadata
 */
export const ConsentPurpose = t.type({
  /** tracking purpose */
  purpose: t.string,
  /** whether the user has given consent for this purpose */
  consent: t.boolean,
});

/** Type override */
export type ConsentPurpose = t.TypeOf<typeof ConsentPurpose>;

/**
 * Preference store pagination key
 *  - Used as **lastKey** in query responses
 *  - Used as **startKey** in query requests
 */
export const PreferenceStorePaginationKey = t.intersection([
  t.type({
    /** partition key, if not entered inferred from partition input */
    partition: t.string,
    /** in prod, userId is the userId and partition concatenated */
    userId: t.string,
    /** Whether this last key value is decrypted or plaintext */
    decryptionStatus: valuesOf(DecryptionStatus),
  }),
  t.partial({
    /** timestamp of the last record */
    timestamp: t.string,
    /** updated timestamp of the last record */
    updatedAt: t.string,
  }),
]);

/** Type override */
export type PreferenceStorePaginationKey = t.TypeOf<
  typeof PreferenceStorePaginationKey
>;

/**
 * Preference store Admin API query request.
 * - Routes:
 *    - **GET** _/v1/consent-preferences_
 *
 * TODO: Deprecated: To be cleaned up in the future
 */
export const QueryConsentRecords = t.intersection([
  t.type({
    /** Partition key */
    partition: t.string,
  }),
  t.partial({
    /** List of unique identifiers */
    identifiers: t.array(t.string),
    /** Max number of records to return */
    limit: t.number,
    /** Consent record timestamp to start before */
    timestampBefore: t.string,
    /** Consent record timestamp to start after */
    timestampAfter: t.string,
    /** Most recent sync timestamp to start before */
    updatedBefore: t.string,
    /** Most recent sync timestamp to start after */
    updatedAfter: t.string,
    /** last record returned; new request will start at the next record */
    startKey: PreferenceStorePaginationKey,
  }),
]);

/** Override type. */
export type QueryConsentRecords = t.TypeOf<typeof QueryConsentRecords>;

/**
 * Preference store Admin API query response body.
 * - Routes: **GET** _/v1/consent-preferences_
 *
 *  Deprecated: To be cleaned up in the future
 */
export const ConsentRecordsQueryResponse = t.intersection([
  t.type({
    nodes: t.array(
      t.intersection([
        t.type({
          /** User ID */
          userId: t.string,
          /** The partition key */
          partition: t.string,
          /** last consent event timestamp (ISO 8601) */
          timestamp: t.string,
          /** The decrypted status */
          decryptionStatus: valuesOf(DecryptionStatus),
          /** purposes */
          purposes: t.record(t.string, t.boolean),
        }),
        t.partial({
          /**
           * Last-modified timestamp (ISO 8601 format)
           *
           * - **updatedAt**: Represents the timestamp when the record was last modified in the preference store.
           * - **timestamp**: Represents the timestamp when the user actually provided consent.
           */
          updatedAt: t.string,
          /** metadata */
          metadata: t.string,
          /** last updated for metadata */
          metadataTimestamp: t.string,
        }),
        t.partial(PreferenceStoreConsentFields.props),
      ]),
    ),
  }),
  t.partial({
    /** for pagination */
    lastKey: PreferenceStorePaginationKey,
  }),
]);

/** Type override */
export type ConsentRecordsQueryResponse = t.TypeOf<
  typeof ConsentRecordsQueryResponse
>;

/**
 * Preference store Admin API update response body.
 * Routes: **PUT** _/v1/preferences_
 */
export const UpsertPreferenceRecordsRequest = t.intersection([
  t.type({
    /** The preference store records to update */
    records: t.array(PreferenceUpdateItem),
  }),
  t.partial({
    /** Whether to skip workflow triggers */
    skipWorkflowTriggers: t.boolean,
    /** Whether to force trigger workflows */
    forceTriggerWorkflows: t.boolean,
  }),
]);

/** Override type. */
export type UpsertPreferenceRecordsRequest = t.TypeOf<
  typeof UpsertPreferenceRecordsRequest
>;

/**
 * Preference store Admin API update response body.
 * - Routes: **PUT** _/v1/preferences_
 */
export const UpsertPreferenceRecordsResponse = t.type({
  nodes: t.array(
    t.intersection([
      PreferenceStoreKeyConditionals,
      t.type({
        /** The system attributes of the record */
        system: PreferenceStoreSystemAttributes,
        /** Consent management related fields */
        consentManagement: t.partial(PreferenceStoreConsentFields.props),
      }),
      t.partial({
        /** metadata */
        metadata: t.array(
          t.type({
            key: t.string,
            value: t.string,
          }),
        ),
        /** last updated for metadata */
        metadataTimestamp: t.string,
      }),
    ]),
  ),
  success: t.boolean,
});

/** Type override */
export type UpsertPreferenceRecordsResponse = t.TypeOf<
  typeof UpsertPreferenceRecordsResponse
>;

/**
 * Preference store Admin API query request.
 * - Routes:
 *    - **POST** _/v1/preferences/:partition/query_
 */
export const QueryPreferenceRecords = t.partial({
  /** filter by fields */
  filter: t.partial({
    /** List of unique identifiers */
    identifiers: t.array(
      t.intersection([
        t.type({
          /** The identifier value */
          value: t.string,
        }),
        t.partial({
          /** The identifier name */
          name: t.string,
        }),
      ]),
    ),
    system: t.partial({
      /** Updated before timestamp */
      updatedBefore: t.string,
      /** Updated after timestamp */
      updatedAfter: t.string,
    }),
    /** Consent record timestamp to start before */
    timestampBefore: t.string,
    /** Consent record timestamp to start after */
    timestampAfter: t.string,
  }),
  /** Max number of records to return */
  limit: t.number,
  /** cursor for pagination */
  cursor: t.string,
});

/** Override type. */
export type QueryPreferenceRecords = t.TypeOf<typeof QueryPreferenceRecords>;

/**
 * Preference store Admin API query response body.
 * - Routes: **POST** _/v1/preferences/:partition/query_
 * - Note: Includes Preference Topics and Options
 */
export const PreferenceRecordsQueryResponse = t.intersection([
  t.type({
    nodes: t.array(PreferenceQueryResponseItem),
  }),
  t.partial({
    /** The base64 encoded(PreferenceStorePaginationKey) cursor for pagination */
    cursor: t.string,
  }),
]);

/** Type override */
export type PreferenceRecordsQueryResponse = t.TypeOf<
  typeof PreferenceRecordsQueryResponse
>;

export const LLMFeatures = t.partial({
  dataSubCategoryDescription: t.boolean,
  subDataPointDescription: t.boolean,
  subDataPointSamples: t.boolean,
  // This should be using the enum from Default DataSubCategoryType
  // but it's too easy to make a typo mistake in LD and I don't want that typo to cause errors in the classifier process
  // so we just type it as a string here
  dataSubCategoryBlockList: t.array(t.string),
  useFullyQualifiedId: t.boolean,
});

/** Type of LLMFeatures */
export type LLMFeatures = t.TypeOf<typeof LLMFeatures>;

/**
 * These payload types are used across Transcend to allow for Integration instances,
 * and downstream webhook integrations to process different parts of a DSR.
 *
 * They eschew any AVC Bulk notifications (for now, as of March 25, 2025) as those payload
 * types don't fall under the purview of an Integration's automated processing.
 */
export const TranscendWebhookIntegrationBasePayload = t.union([
  DataSiloTranscendWebhookNotification,
  EnricherTranscendWebhookNotification,
]);

/** Override type. */
export type TranscendWebhookIntegrationBasePayload = t.TypeOf<
  typeof TranscendWebhookIntegrationBasePayload
>;

/** Custom function code script as a string. */
export const CustomFunctionCode = t.type({
  /** Base 64 code string. */
  base64Code: t.string,
});

/** Override type. */
export type CustomFunctionCode = t.TypeOf<typeof CustomFunctionCode>;

/** Custom function code script as a string. */
export const CustomFunctionCodeContext = t.intersection([
  t.type({
    /** User defined environment variables. The values are encrypted. */
    userDefinedEncryptedEnv: t.record(t.string, t.string),
    /** Hosts deno is allowed to contact. */
    allowedHosts: t.array(t.string),
  }),
  t.partial({
    /**
     * Allow importing from Deno approved known-good repos.
     * Defaults to false.
     */
    allowThirdPartyImports: t.boolean,
    /** Timeout for this function run, in milliseconds. */
    timeoutMs: t.number,
  }),
]);

/** Override type. */
export type CustomFunctionCodeContext = t.TypeOf<
  typeof CustomFunctionCodeContext
>;

// TODO: https://transcend.height.app/T-43754 - Add log level control.
export const CustomFunctionExecuteRequest = t.intersection([
  t.type({
    /** Metadata about the request. */
    metadata: t.intersection([
      t.type({
        /** Organisation. */
        organization: t.type({
          /** ID. */
          id: dbModelId('organization'),
          /** URI. */
          uri: t.string,
        }),
        /** Data Silo. */
        dataSilo: t.type({
          /** ID. */
          id: dbModelId('dataSilo'),
          /** Title. */
          title: t.string,
        }),
        /** Encrypted CEK context for a silo's DSR. */
        encryptedCEKContext: t.string,
      }),
      t.partial({
        /** DSR, if any. */
        request: t.type({
          /** ID. */
          id: dbModelId('request'),
        }),
        /** Request enricher, if in the enrichment workflow. */
        requestEnricher: t.type({
          /** Request Enricher ID. */
          id: dbModelId('RequestEnricher'),
        }),
        /** Profile, if in the DSR resolution phase. */
        profile: t.type({
          /** Profile ID. */
          id: dbModelId('profile'),
        }),
      }),
    ]),
    /**
     * Signed function context.
     *
     * @see CustomFunctionCode codec.
     */
    signedContextJwt: t.string,
    /**
     * Signed code to execute.
     *
     * @see CustomFunctionCodeContext codec.
     */
    signedCodeJwt: t.string,
    /** Payload to provide to the custom function code. */
    payload: TranscendWebhookIntegrationBasePayload,
  }),
  t.partial({
    /** If this is a test function run. */
    isTest: t.boolean,
    /** Optional DH payload that contains overrides for certain fields like env vars. */
    dhEncrypted: t.string,
    /** Encrypted identifier string. */
    encryptedIdentifier: t.string,
  }),
]);

/** Override type. */
export type CustomFunctionExecuteRequest = t.TypeOf<
  typeof CustomFunctionExecuteRequest
>;

export const CustomFunctionExecuteResult = schemaToCodec(
  CustomFunctionExecutionResult,
);

/** Override type. */
export type CustomFunctionExecuteResult = t.TypeOf<
  typeof CustomFunctionExecuteResult
>;

export const CustomFunctionSignPayload = t.type({
  /** Plaintext code string. */
  code: t.string,
  /** Code context. */
  context: t.intersection([
    t.type({
      /**
       * User defined environment variables. The values are not encrypted yet, and will
       * be encrypted upon signing.
       */
      userDefinedEnv: t.record(t.string, t.string),
      /** Hosts deno is allowed to contact. */
      allowedHosts: t.array(t.string),
    }),
    t.partial({
      /**
       * Allow importing from Deno approved known-good repos.
       * Defaults to false.
       */
      allowThirdPartyImports: t.boolean,
      /** Timeout for this function run, in milliseconds. */
      timeoutMs: t.number,
    }),
  ]),
});

/** Override type. */
export type CustomFunctionSignPayload = t.TypeOf<
  typeof CustomFunctionSignPayload
>;

export const CustomFunctionExecuteOverridePayload = schemaToCodec(
  CustomFunctionExecutionOverridePayload,
);

/** Override type. */
export type CustomFunctionExecuteOverridePayload = t.TypeOf<
  typeof CustomFunctionExecuteOverridePayload
>;

export const CustomFunctionGeneratedArgs = t.intersection([
  t.type({
    /** Environment variables to use. */
    environment: t.record(t.string, t.string),
    /** Webhook payload. */
    payload: TranscendWebhookIntegrationBasePayload,
    /** Nonce to use with a webhook payload. */
    nonce: t.string,
  }),
  t.partial({
    /** Decrypted identifier. */
    decryptedIdentifier: t.string,
  }),
]);

/** Override type. */
export type CustomFunctionGeneratedArgs = t.TypeOf<
  typeof CustomFunctionGeneratedArgs
>;

export const TenantConfigResponse = t.partial({
  /** The parameters for the tenant */
  parameters: t.string,
  /** The secrets for the tenant */
  secrets: t.string,
});

/**
 *  Response type of tenant config from transcend backend
 */
export type TenantConfigResponse = t.TypeOf<typeof TenantConfigResponse>;

/* eslint-enable max-lines */
