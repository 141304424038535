import isEmpty from 'lodash/isEmpty';

import {
  AssessmentQuestionType,
  AssessmentsDisplayLogicAction,
  ComparisonOperator,
  LogicOperator,
} from '@transcend-io/privacy-types';
import { ObjByString } from '@transcend-io/type-utils';

import { ID } from '@main/schema-utils';

import { DISPLAY_LOGIC_COMPARISON_OPERATOR_TO_FUNCTION_MAP } from './conditionalLogicHelpers';

/** The assessment questions to show based on their display logic */
interface AssessmentQuestionToShow {
  /** the ID of the assessment question */
  id: ID<'assessmentQuestion'>;
  /** the display logic of the assessment question */
  displayLogic?: any;
  /** the selected answers */
  selectedAnswers?: {
    /** The additional context */
    additionalContext?: ObjByString;
    /** the value of the answer */
    value: string;
  }[];
  /** the questions'reference ID */
  referenceId: string;
  /** the type of the question */
  type: AssessmentQuestionType;
  /** the index of the assessment question */
  index: number;
}

/**
 * Determine questions that should be shown to the user, by executing their conditional
 * logic against the answers provided for other questions.
 *
 * @param questions - The questions to filter. All questions should be from the same group.
 * @returns questions that should be displayed, based on their conditional logic and the answers to other questions
 */
export const getAssessmentQuestionsToShow = (
  questions: AssessmentQuestionToShow[],
): AssessmentQuestionToShow[] => {
  const questionsToKeep: AssessmentQuestionToShow[] = [];

  questions.forEach((question) => {
    const { displayLogic } = question;
    if (!isEmpty(displayLogic)) {
      const {
        action,
        // TODO: https://transcend.height.app/T-33541 - support rules nested more than one level deep
        nestedRule: { logicOperator, rules = [] },
      } = displayLogic || {};
      let skipRemainingRules = false;

      rules.forEach(
        (
          {
            dependsOnQuestionReferenceId,
            comparisonOperator,
            comparisonOperands,
          },
          i,
        ) => {
          if (skipRemainingRules) {
            return;
          }
          const isLastRule = i === rules.length - 1;

          const questionToCheck = questions.find(
            ({ referenceId }) => referenceId === dependsOnQuestionReferenceId,
          );

          if (!questionToCheck) {
            return;
          }

          const { selectedAnswers } = questionToCheck;
          let questionIsAMatch = false;

          if (
            // shown/not shown operators don't depend on the questionToCheck having selected answers
            comparisonOperator === ComparisonOperator.IsNotShown ||
            comparisonOperator === ComparisonOperator.IsShown ||
            (selectedAnswers &&
              selectedAnswers.length > 0 &&
              // the first value is sometimes undefined, I think due to RHF being in the process of populating defaults
              selectedAnswers[0])
          ) {
            questionIsAMatch =
              DISPLAY_LOGIC_COMPARISON_OPERATOR_TO_FUNCTION_MAP[
                comparisonOperator
              ]({
                questionToCheck: {
                  type: questionToCheck.type,
                  referenceId: questionToCheck.referenceId,
                  selectedAnswers: (selectedAnswers ?? []).map((a) => ({
                    ...a,
                    /**
                     * questions whose answers are dynamic (e.g., Data Category) do not have a 'value' property
                     * when picked with the FormSelectAssessmentAnswers component. We need to retrieve the value
                     * (e.g., the Data Category ID) from the 'additionalContext' property.
                     */
                    value: a.value ?? a.additionalContext?.id,
                  })),
                },
                comparisonOperands,
                questionsToKeep,
              });

            // If this is the last rule and none of the previous rules
            // have been skipped, then apply the action
            if (isLastRule) {
              if (
                (questionIsAMatch &&
                  action === AssessmentsDisplayLogicAction.Show) ||
                (!questionIsAMatch &&
                  action === AssessmentsDisplayLogicAction.Skip)
              ) {
                questionsToKeep.push(question);
              }
            }
            // If the logic operator is "AND", we just need one rule that doesn't
            // match to determine that the action should not be taken
            else if (logicOperator === LogicOperator.And && !questionIsAMatch) {
              skipRemainingRules = true;

              if (
                !questionIsAMatch &&
                action === AssessmentsDisplayLogicAction.Skip
              ) {
                questionsToKeep.push(question);
              }
            }
            // If the logic operator is "OR", we just need one rule that matches
            // to determine that the action should be taken
            else if (logicOperator === LogicOperator.Or && questionIsAMatch) {
              skipRemainingRules = true;

              if (action === AssessmentsDisplayLogicAction.Show) {
                questionsToKeep.push(question);
              }
            }
          }
        },
      );
    } else {
      questionsToKeep.push(question);
    }
  });

  return questionsToKeep;
};
