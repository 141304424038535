export const RAINBOW_COLORS = {
  brown1: '#8f6546',
  brown2: '#bc9376',
  red1: '#fe6354',
  red2: '#fe9f96',
  red3: '#fec4bf',
  orange1: '#ff8933',
  orange2: '#ffaf75',
  orange3: '#ffc79e',
  yellow1: '#f5ca31',
  yellow2: '#f5d871',
  yellow3: '#f5e098',
  yellow4: '#fffae7',
  lime1: '#83d400',
  lime2: '#abd46a',
  lime3: '#c4d4a9',
  mint1: '#44cfad',
  mint2: '#81dbc6',
  mint3: '#a7ded1',
  blue1: '#339dff',
  blue2: '#75bdff',
  blue3: '#9ed0ff',
  indigo1: '#3333ff',
  indigo2: '#7575ff',
  indigo3: '#9e9eff',
  purple1: '#8d33ff',
  purple2: '#b275ff',
  purple3: '#c99eff',
  pink1: '#f250d8',
  pink2: '#f280e0',
  pink3: '#f2b1e8',
};

export const DARK_RAINBOW_COLOR_NAMES = [
  'red1',
  'orange1',
  'yellow1',
  'lime1',
  'mint1',
  'blue1',
  'purple1',
  'pink1',
  'brown1',
  'transcendNavy2',
  'gray3',
] as const;

export const LIGHT_RAINBOW_COLOR_NAMES = [
  'red2',
  'orange2',
  'yellow2',
  'lime2',
  'mint2',
  'blue2',
  'purple2',
  'pink2',
  'brown2',
  'transcendNavy3',
  'transcendNavy4',
  'gray2',
] as const;

/**
 * These are colors that are always fixed and cannot be customized
 */
export const FIXED_COLOR_LIST = {
  // /////////////// //
  // Admin Dashboard //
  // /////////////// //

  negativeHighlight: '#fe6354', // red1
  success: '#44cfad', // mint1
  positiveHighlight: '#44cfad', // mint1
  danger: '#fe6354', // red1
  warning: '#f5ca31', // yellow1

  // /////////////// //
  // Transcend Theme //
  // /////////////// //

  transcend: '#3333ff',
  transcend2: '#7575ff',
  transcend3: '#9e9eff',
  transcend4: '#d6d6ff',
  transcendNavy: '#1c2938',
  transcendNavy2: '#535f6e',
  transcendNavy3: '#7e8793',
  transcendNavy4: '#d4d6db',

  // //////////////////////////////////////// //
  // Shades of black & white                  //
  // Use transcendNavy 2-4 for shades of gray //
  // //////////////////////////////////////// //

  black: '#000',
  white: '#FFFFFF',
  gray1: '#f8f9fb',
  gray2: '#fbfbfb',
  gray3: '#e8e8e8',
  gray4: '#EDEFF0',
  // /////// //
  // Rainbow //
  // /////// //
  ...RAINBOW_COLORS,
};
