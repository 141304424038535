/**
 * the docgen fields on an object
 */
interface TDocgen {
  /** misc attrs */
  __docgenInfo?: any;
  /** the name of the component */
  displayName?: string;
}
/**
 * Currently storybook auto-injects the following params into every component
 * object. Until we can figure out how to turn this feature off, in stories we
 * will need to use this helper function when object props matter (e.g. forms)
 *
 * @param object - the object to strip
 * @returns the stripped object
 */
export const stripDocgenFromObject = <T>(object: TDocgen & T): T => {
  // eslint-disable-next-line no-underscore-dangle
  if (object.__docgenInfo) {
    // eslint-disable-next-line no-underscore-dangle,no-param-reassign
    delete object.__docgenInfo;
  }
  if (object.displayName) {
    // eslint-disable-next-line no-param-reassign
    delete object.displayName;
  }
  return object;
};

/**
 * Currently storybook auto-injects the following params into every component
 * object. Until we can figure out how to turn this feature off, in stories we
 * will need to use this helper function when object props matter (e.g. forms)
 *
 * @param input - the objects to strip
 * @returns the stripped objects
 */
export const stripDocgenFromObjects = <T>(input: (TDocgen & T)[]): T[] =>
  input.map(stripDocgenFromObject);

export const CommonStorybookArgs = {
  BootstrapVariant: (includeOutline?: boolean) => ({
    variant: {
      control: 'select',
      options: [
        'success',
        'warning',
        'danger',
        'info',
        'primary',
        'secondary',
        'light',
        'dark',
        ...(includeOutline
          ? [
              'outline-success',
              'outline-warning',
              'outline-danger',
              'outline-info',
              'outline-primary',
              'outline-secondary',
              'outline-light',
              'outline-dark',
            ]
          : []),
        undefined,
      ],
    },
  }),
} as const;
