/* eslint-disable max-lines */
import {
  DatabaseDriver,
  RequestDataSiloStatus,
  SQLDriverWithDataMapping,
} from '@transcend-io/privacy-types';
import { getValues } from '@transcend-io/type-utils';

import { compareVersions, switchCase } from '@main/utils';

import { DatabaseColumnType } from './enums';

export const SupportedDatabaseDriver: Set<string> = new Set(
  getValues(DatabaseDriver),
);

/**
 * The encryption algorithm to be used for encrypting and decrypting content.
 */
export const CONTENT_ENCRYPTION_ALGORITHM = 'aes-256-gcm';

/** Error to throw when invalid signature */
export const INVALID_SIGNATURE_ERROR = 'Signature did not pass verification.';

/**
 * Error message to show when a request data silo is responded to
 * but the request is no longer in an active state
 */
export const NON_ACTIVE_PRIVACY_REQUEST_MESSAGE =
  'This DSR is not in a status where it can be modified. It may have already been sent to the data subject.';

/** Error message when bearer is in invalid shape */
export const BEARER_TOKEN_INVALID_MESSAGE =
  'The API key appears malformed. It should be of the form "{ authorization: "Bearer xyz123-my-api-key" }"';

/**
 * 96-bit random initialization vector (96-bit random IVs for GCM mode)
 */
export const IV_RANDOMNESS = 12;

/** Temporary subdatapoint name for integrations without subdatapoint discovery. */
export const DEFAULT_SUBDATAPOINT = 'Global Profile';

/**
 * JSON Bulk Upload can sometimes send some fairly large payloads.
 * We set our body-parser limit a bit higher than we'd need in order
 * to simplify the API in the majority of use cases for our customers.
 *
 * For reference ~1/100k ACCESS requests for patreon result in a payload
 * larger than 30mb. It came in at 42mb and so the limit is set at 50mb
 * to give a bit of leeway.
 */
export const BODY_PARSER_OPTIONS = {
  // bulk upload needs this
  json: { limit: '50mb', extended: true },
  raw: { limit: '50mb', type: '*/*' },
  urlencoded: { limit: '30mb', extended: true },
};

/**
 * The Elliptic Curve to use with Diffie Hellman.
 */
export const ECDH_CURVE = 'secp384r1';

/** Audience when no organization uri is in context for multi tenant sombra */
export const MULTI_TENANT_CONFIG_AUDIENCE = 'SOMBRA_INTERNAL_TRANSCEND';

/** We assume this is the sombra version when none currently TODO: https://github.com/transcend-io/main/issues/6723 */
export const MINIMUM_SUPPORTED_SOMBRA_VERSION = '5.4.0';

/** Names of the database that is supported */
export const SUPPORTED_DATABASE_INTEGRATION_NAMES = [
  'database',
  'googleBigQuery',
  'snowflake',
  'mongoDb',
  'amazonDynamodb',
  'amazonS3',
  's3Parquet',
  'databricksLakehouse',
  'oracleDb',
  'amazonAurora',
  'googleCloudSpanner',
  'redis',
];

/** Names of the database that are support via API key upload */
export const CODE_INTEGRATION_NAMES = ['cron', 'server'];

/** The MIME types of objects that the encryptSampleAmazonS3 Sombra endpoint supports */
export const SUPPORTED_AWS_S3_OBJECT_MIME_TYPES = [
  'application/json',
  'application/xml',
  'text/css',
  'text/csv',
  'text/html',
  'text/plain',
  'text/xml',
];

export const DATABASE_METADATA_FIELDS = ['bigQuery', 'databricksLakehouse'];

/**
 * Minimum Sombra version required to reliably use bulk PaV email sending features.
 */
export const BULK_PAV_MINIMUM_SOMBRA_VERSION = '7.16.0';

/** String informing the reader that there is no subdatapoint available for this operation. */
export const NO_SUBDATAPOINT_AVAILABLE = 'NO_SUBDATAPOINT_AVAILABLE';

/** Regex for checking named parameters in a SQL query (ignores inside quotes) */
export const NAMED_PARAMETER_REGEX = `@([\\w\\d]*)(?=(?:[^']*'[^']*')*[^']*$)(?=(?:[^"]*"[^"]*")*[^"]*$)`;

/** Regex for checking format of multiple identπifier */
export const TERADATA_MULTIPLE_IDENTIFIER = /{{[\w\d]+[[0-9]+]}}/;

/**
 * This is a valid ECDH public key, however it is meaningless. Only used to fill into defaults where a public key is required and must be a valid key
 */
export const DEFAULT_SOMBRA_ECDH_PUBLIC_KEY =
  // eslint-disable-next-line max-len
  'BFGZewWeY9H9xk9kBhpr49IkPQ3yLyWVdhskMDXxNPKp6jib8lV0RnFlJqG/rUJj5/t+rB250DWSA2PuRETOmQT6JC+fARrGoAhHYTG3ZYCNjGIH1kvJ9XeKqCo7IZqDqA==';

/**
 * Delimeter for S3 file type data point levels
 */
export const FILE_TYPE_DATA_POINT_LEVEL_DELIM = '___';

/**
 * Character to check during S3 schema discovery to accept all files without any extensions
 */
export const S3_ACCEPT_ALL_FILES = '*';

/**
 * Scanned object s3 key prefix
 */
export const S3_SCANNED_OBJECT_STATUS_UPDATE_KEY_PREFIX = 'scanned-object';

/**
 * content type to store scanned object in s3
 */
export const S3_SCANNED_OBJECT_STATUS_UPDATE_CONTENT_TYPE = 'application/json';

export const SOMBRA_RESPONSE_SOURCE_HEADER_NAME = 'x-sombra-response-source';

export const SOMBRA_TEST_EMAIL_FAILURE_POINT_HEADER_NAME =
  'x-sombra-failure-point';

/**
 * The source of a sombra response
 */
export enum SombraResponseSource {
  Proxied = 'proxied',
  Sombra = 'sombra',
}

/**
 * headers to return when the request is purely proxied. Useful for differentiating
 * between internal sombra errors and external error statuses
 */
export const PROXIED_RESPONSE_SOURCE_HEADERS = {
  [SOMBRA_RESPONSE_SOURCE_HEADER_NAME]: SombraResponseSource.Proxied,
};

/**
 * headers to return when the request is purely proxied. Useful for differentiating
 * between internal sombra errors and external error statuses
 */
export const SOMBRA_RESPONSE_SOURCE_HEADERS = {
  [SOMBRA_RESPONSE_SOURCE_HEADER_NAME]: SombraResponseSource.Sombra,
};

/** Region array delimiter for the AWS integration */
export const AWS_REGION_ARRAY_DELIMITER = ',';

export const GENERAL_COMMA_SEPARATED_ITEMS_REGEX =
  '^[a-zA-Z0-9]+(,[a-zA-Z0-9]+)*$';

export const SOMBRA_MULTI_TENANT_JOB_SCHEDULER_AUDIENCE = 'multi-tenant';

/** A magic string to represent empty values in the SecretMap */
export const SECRET_MAP_EMPTY_VALUE =
  'SECRET-MAP-EMPTY-VALUE-5151701a-17fd-49d7-9aa5-33b75ab452de';

export const SOMBRA_LLM_OUTPUT_ERROR_MESSAGE =
  'The LLM Classifier returned an invalid format, ' +
  'try updating to the latest version of sombra and LLM classifier to fix';

const BINARY_TYPE_HANDLER = switchCase<
  string,
  [string],
  Exclude<
    SQLDriverWithDataMapping,
    // These drivers do not support binary types
    'Amazon Redshift' | 'PostgreSQL Unicode' | 'Google BigQuery'
  >
>({
  [SQLDriverWithDataMapping.microsoftsql]: (columnName) => `HEX(${columnName})`,
  [SQLDriverWithDataMapping.mysql]: (columnName) => `HEX(${columnName})`,
  [SQLDriverWithDataMapping.oracle]: (columnName) => `RAWTOHEX(${columnName})`,
  [SQLDriverWithDataMapping.snowflake]: (columnName) =>
    `HEX_ENCODE(${columnName})`,
  [SQLDriverWithDataMapping.databrickslakehouse]: (columnName) =>
    `TO_HEX(${columnName})`,
  [SQLDriverWithDataMapping.ibmdb2]: (columnName) => `HEX(${columnName})`,
  [SQLDriverWithDataMapping.maria]: (columnName) => `HEX(${columnName})`,
})((columnName) => columnName);

export const DEFAULT_SQL_QUERY_TRANSFORMATIONS_BY_DATA_TYPE: {
  [k in DatabaseColumnType]?: (
    columnName: string,
    dbDriver?: SQLDriverWithDataMapping,
  ) => string;
} = {
  [DatabaseColumnType.Binary]: (columnName, dbDriver) =>
    dbDriver
      ? BINARY_TYPE_HANDLER(dbDriver as any, columnName)
      : `HEX(${columnName})`,
  [DatabaseColumnType.Varbinary]: (columnName, dbDriver) =>
    dbDriver
      ? BINARY_TYPE_HANDLER(dbDriver as any, columnName)
      : `HEX(${columnName})`,
  [DatabaseColumnType.Bytea]: (columnName) => `ENCODE(${columnName}, 'hex')`,
};

export const SOMBRA_PIKO_PORT_MAPPING = {
  proxy: 8000,
  upstream: 8001,
  admin: 8002,
  gossip: 8003,
};

/**
 * Mapping of partition to identifier
 *
 * Except the customer listed below all others use email identifier
 * This is a hardcoded list of mapping based on their identifiers setup on prod
 * TODO: https://transcend.height.app/T-43449 - Remove this hardcoded mapping and use postgres
 */
export const PARTITION_IDENTIFIER_MAPPING = {
  '8bb139e4-7c3e-463f-bcab-dcffc8cf43e4': 'user_id', // fuboTv
  '0016865d-822d-4574-8235-546152a5b53e': 'userId', // textnow-com
};

/**
 * Text to use user trusted payload when encrypting consent identifiers
 *
 * This is specifically used to move the identifier payload to DHChannel
 * so that backend does not have access to the identifiers in plaintext
 *
 * TODO: https://transcend.height.app/T-44001 - Remove this after one successful deploy
 */
export const REFER_USER_TRUSTED_PAYLOAD = 'REFER_USER_TRUSTED_PAYLOAD';

/** old hardcoded value that was previously ignored */
export const DEPRECATED_DATA_SILO_RESPONSE_STATUS = 'READY';

/**
 * List of allowed Request data silo status values for data silo response endpoints
 * These statuses can be provided when responding to a data silo request
 */
export const ALLOWED_DATA_SILO_RESPONSE_RDS_STATUSES = [
  DEPRECATED_DATA_SILO_RESPONSE_STATUS,
  RequestDataSiloStatus.Resolved,
  RequestDataSiloStatus.Skipped,
  RequestDataSiloStatus.SkippedDueToException,
  RequestDataSiloStatus.ActionRequired,
  RequestDataSiloStatus.Waiting,
] as const;

/**
 * List of allowed Profile Data Point status values for data silo response endpoints
 * These statuses can be provided when responding to a data silo request
 */
export const ALLOWED_DATA_SILO_RESPONSE_PDP_STATUSES = [
  // ProfileDataPointStatus is not accessible in @main/sombra-types
  'RESOLVED',
  'SKIPPED',
  'SKIPPED_DUE_TO_EXCEPTION',
  'WAITING',
] as const;

/* eslint-disable max-len */
/**
 * The sombra changelog.  It is required this be updated for all minor/major version changes.
 * It is optional for patches.
 */
export const CHANGELOG = {
  '5.5.0': `New route: "/proxy/index".

This allows for integrations like slack, jira and unbounce to create an index of documents
that contain PII.`,
  '5.6.0': `New route: "/saas/encrypt-identifier".

We are building towards a security model where transcend's backend is not able to view
the data subject identifiers in plaintext (i.e. email, phone number, uuids...).

This route allows our backend to encrypt an identifier. Eventually, all identifiers
will be encrypted and this route can be removed, however it serves as a bridge.

All new integrations that we build use this new encrypted identifier framework and will thus
require a sombra gateway to update past this version.
`,
  '5.7.0':
    'Adds additional support for the encrypted identifiers project described in 5.6.0',
  '5.8.0': `New route: /employee/downloadCEK

This route allows for an employee on the admin dashboard to fetch the encryption context
for a request in order to download a ZIP of all files for that request.
  `,
  '5.9.0':
    'Adds additional support for download a zip of files via the admin dashboard',
  '5.10.0': 'Updates the PATCH `/tenant` to be diffie-hellman protected',
  '5.11.0': 'Added support for additional audit tracing on sombra',
  '5.12.0':
    'Additional support for the encrypted identifiers project described in 5.6.0',
  '5.13.0': 'Adds additional validation to environment variables validation',
  '6.0.0': `Sombra will now "phone home" to transcend on start to notify transcend of any configuration changes
that may have been deployed via environment variables. This will allow the changes to take affect immediately
on server start rather than waiting for the transcend backend to poll the gateway for its updated configuration.`,
  '6.1.0': `Adds new route: "/saas/decrypt-identifier"

This is a route that enables administrators to view an identifier (i.e. email, phone number) in plaintext.
This is required for new integrations where Transcend is not capable of viewing these identifiers
in plaintext.`,
  '6.2.0': `Filters out datadog headers while sending outbound requests. This caused some errors on particular
saas-tool integrations`,
  '6.3.0': 'Bumps allowed Sombra header sizes to 16KB',
  '6.4.0': 'Refactor to improve test coverage reliability of code',
  '6.5.0':
    'Fixes a minor bug that was affecting the Amplitude integration outbound requests',
  '6.6.0': `Sombra is now capable of signing request identifiers during enrichment
removing the need for your server to maintain a private key.

More details can be found in: https://docs.transcend.io/docs/identity-enrichment`,
  '6.7.0': 'Adds additional functionality for upcoming saas-tool integrations',
  '6.8.0': 'Adds additional functionality for upcoming saas-tool integrations',
  '6.9.0': `Adds new route: "/employee/reSign/encryptedCEKContexts"

This route allows for the cycling of encryption contexts for existing requests.
This is useful when rotating Sombra's keys, and when these encryption contexts expire
and need to re-initiated`,
  '6.10.0': 'Infrastructure to improve testing',
  '6.11.0': 'Adds preliminary support for Sombra to perform a key rotation',
  '6.12.0':
    'Adds a new route to re-sign the data silo settings when a key rotation is performed on Sombra',
  '6.13.0': 'Sombra key rotation is officially supported',
  '6.14.0': 'Add support for Ethnio integration',
  '6.15.0': 'Add support for Ethnio integration',
  '6.16.0': 'Fixes a minor bug related to key rotation',
  '6.17.0': `When running sombra on-premise, do not expose routes
that are only used when sombra is run in a multi-tenant fashion.`,
  '6.18.0':
    'Filter out "x-forwarded-proto" header which disrupted Salesforce Pardot integration',
  '6.19.0': 'Remove GET /tenant route as it was not used',
  '6.20.0': `Removes an HTTP restriction that prevented:
- the validation of credentials for the Kustomer integration
- the revocation of access tokens when most integrations (i.e. gmail) are deleted from the datamap`,
  '6.21.0':
    'Patches a bug in the transform SaaS context route, required to migrate delighted and mailgun data silos',
  '6.22.0': 'Adds additional functionality for the Heap integration',
  '6.23.0':
    'Errors encountered when uploading enriched identifiers will be passed back to the client',
  '6.24.0': 'Updates Sombra node version to v14.3',
  '6.25.0':
    'Adds additional validation to ensure requestId and employeeUserId are v4 uuids',
  '6.26.0':
    'The `contains-content` route can now match retrieved data with a regular expression',
  '6.27.0': 'Raises Keep-Alive timeout value on the servers to 65 seconds',
  '6.28.0':
    'Adds error handling to prevent server from crashing on file uploads',
  '6.29.0': 'Adds stream error handling to proxySaaSRedact',
  '6.30.0':
    'Adds the ability to send a secure email as an automated part of a request',
  '6.31.0': 'Adds support for encrypted identifiers to containsContent',
  '6.32.0':
    'Adds a new endpoint that streams binary files in an encrypted fashion',
  '6.33.0': 'Adds a new endpoint needed for the Prompt a Vendor feature',
  '6.34.0':
    'Sombra can open a Diffie-Hellman channel when proxying requests to Transcend backend',
  '6.35.0': 'Sombra no longer supports the x-sombra-jwt-auth header',
  '6.36.0':
    'Sombra correctly sets the host header when forwarding requests to the Transcend backend',
  '6.37.0': 'Sombra is now capable of sending emails to vendors',
  '6.38.0': 'Sombra is now capable of sending emails to vendors',
  '6.39.0': 'Sombra can now apply a sha 256 mutator to identifiers',
  '6.40.0':
    'Sombra can now fill in email templates with arbitrary template variables',
  '6.41.0': 'Adds more specific error handling on the external side of sombra',
  '6.42.0': 'Adds node-odbc and unixODBC for ODBC support in Sombra',
  '6.43.0': 'Updates Sombra node version to v14.16',
  '6.44.0': 'Patches a bug in how Sombra pipes requests to Transcend',
  '6.45.0': 'Updates Sombra to be packaged with Yarn V2',
  '6.46.0': 'Emails can now be sent with Diffie Hellman encrypted payloads',
  '7.0.0':
    'Updated the package manager for runtime dependencies, which may increase memory usage',
  '7.1.0':
    'New route "/v1/data-silo/:dataSiloId/pending-requests/:actionType" for the Cron Job Integration',
  '7.2.0': 'Added embeddedPaths filter to containsContent',
  '7.3.0':
    'Adds two secret map transforms -- "duplicateKey" and "deleteKey" that are needed to add Salesforce functionality',
  '7.4.0':
    'Adds a secret map transform "defaultKey" that is also needed for the Salesforce feature addition',
  '7.5.0': 'Standardizes sombra JSON bulk upload limit at 50mb',
  '7.6.0':
    'SaaS Proxy Routes all return response headers when status code is >= 400',
  '7.7.0':
    'Updates sombra to allow RingCentral, SnapAds, GoogleAds, Delighted, and Pardot to be ready for encrypted identifiers',
  '7.8.0':
    'Updates Sombra Proxy paths to always return a list of encrypted information.',
  '7.9.0': 'Updates Sombra to set sent date on received emails.',
  '7.10.0':
    'Added support for connecting to external databases through Sombra.',
  '7.11.0':
    'Added support for accepting stringified JSON for integrations like Braze',
  '7.12.0': 'Adds support for bulk-encrypting data.',
  '7.13.0':
    'Adds support for signing identifiers attested by employees from the Admin Dashboard.',
  '7.14.0':
    'Adds support for bulk-decrypting data recursively. Bulk data encryption also supports recursive structures.',
  '7.15.0':
    'Exposes ports 5039, 5040, 5041, 5042 in Dockerfile to allow for integration into deployment systems like Aptible.',
  '7.16.0':
    'Increases External Sombra server JSON parser ceiling from 100 KB to 50 MB.',
  '7.17.0': 'Bumped aws-sdk dependency versions.',
  '7.18.0': 'Adds database integration support for all request types.',
  '7.19.0':
    'Logging of email response no longer expects `date` to be present in the body.',
  '7.20.0':
    'Sombra data subject sessions can be created without an email (just a core ID is required)',
  '7.21.0': 'New External Sombra route to transform encrypted identifiers',
  '7.22.0': 'Adds new data action type: ACCOUNT_DELETION',
  '7.23.0': 'Adds JSON path support to stripPaths',
  '7.24.0': 'Adds encrypted identifier support to Server Webhook',
  '7.25.0': 'Add encryptedPaths header to allow returning encrypted fields',
  '7.26.0':
    'Removes optional check for x-sombra-authorization in multi tenant sombra',
  '7.27.0':
    'Allows database integration queries to reference the request id of the DSR',
  '7.28.0':
    'Returns matchDicts per-body from containsContent to support bulk filtering',
  '7.29.0':
    'Adds support to reveal the user email address to the privacy center for display purposes.',
  '7.30.0': 'Adds missing field from nonce for encrypted webhooks',
  '7.31.1':
    'Makes a change that allows for the /v1/data-subject-request route to take in more identifiers',
  '7.32.0': 'Adds support for snowflake DB integration',
  '7.33.0':
    'Allows for oauth configuration to specify separate route for profile picture, email and core identifier',
  '7.34.0': 'Allows for multiple mutators in the same request to be applied',
  '7.35.0':
    'New route -- /proxy/request to be used for outbound requests to remote APIs in all contexts.',
  '7.36.0':
    'Removes reliance on x-transcend-profile-is-encrypted header as all profiles using these routes are now encrypted E2E.',
  '7.37.0':
    'Adds support for end to end encrypting user identifiers in the server webhook integration.',
  '7.38.0': 'De-references usage of logSentRequestEmail.uri',
  '7.39.0':
    'It is now possible to respond to preflight webhook with the title of an email template that you want to trigger.',
  '7.40.0': 'Adds new route /v1/datapoint-chunked.',
  '7.41.0': 'Adds a mutator supporting regex and string replacement',
  '7.42.0': 'Adds RegEx transformers for vectorizing data for classification',
  '7.43.0': 'Adds fetch-schema route',
  '7.44.0': 'Adds vectorize-sample route',
  '7.45.0':
    'Adds /saas/vectorize-sample route to extend /database/vectorize-sample to work for all integrations',
  '7.46.0':
    'Adds mutator for normalizing mobile phone numbers into E.164 format',
  '7.47.0':
    'Add new route to migrate multi-tenant parameters to arrayed values',
  '7.48.0': 'Adds a scope field to the JWT body when signing core identifiers',
  '7.49.0': 'Update proxyRequest Sombra endpoint to support base encryption',
  '7.49.1':
    'Database Data Point Discovery Plugin now generates feature maps for sample subDataPoints for classification',
  '7.50.0':
    'Add ability to convert input JSON to CSV to /v1/datapoint-chunked internal route',
  '7.51.0':
    'Add ability to bulk transform org encrypted values into client encrypted values for decrypting in the browser',
  '7.52.0': 'Save vectorized sub-datapoints samples to S3 instead of Dynamo',
  '7.53.0':
    'Remove Sombra bridges to migrate multi-tenant parameters to arrayed values',
  '7.54.0':
    'Remove ability to convert input JSON to CSV to /v1/datapoint-chunked internal route',
  '7.55.0': 'Removes ACCOUNT_DELETION action type.',
  '7.56.0':
    'Remove the vectorizedSamples attribute from data-point-classifications table',
  '7.57.0': 'Removes check for profileData field in the server webhook',
  '7.58.0':
    'Sombra reveals subdatapoint metadata when encrypting JSON objects.',
  '7.59.0': 'Adds compatibility between sombra and cloudflare.',
  '7.60.0': 'Adds support for mariadb.',
  '7.61.0':
    'Adds support to /proxy/redact to allow redaction of arbitrary strings',
  '7.62.0':
    'Adds support to /proxy/redact to allow redaction of entire messages',
  '7.63.0': 'Sombra can send webhooks for bulked AVC manual notifications',
  '7.64.0':
    'Adds support to enable identifier enrichment to looker using x-sombra-signed-identifiers-paths',
  '7.65.0':
    'Adds route on internal sombra for encrypting and signing a Saas Context value to enable setting secrets in Terraform',
  '7.66.0':
    'Allows for root sombra secret to be revealed one time from multi tenant sombras for deploying seamlessly to on premise',
  '7.67.0': 'Adds support for mongoDb.',
  '7.68.0': 'Adds a mutator to check if identifier is a UUID',
  '7.69.0': 'Filters out unnecessary fields from emitted logs',
  '7.70.0':
    'Exposes additional metadata like coreIdentifier and attributes in the cron integration',
  '7.71.0': 'Adds support for more efficient Snowflake meta-queries',
  '7.72.0': 'Adds support for DynamoDB',
  '7.73.0':
    'Allows sombra to redact specific fields on server webhooks using access-request-visibility: false',
  '7.74.0': 'Improvements to Snowflake meta-queries',
  '7.75.0':
    'Adds support for request types SALE_OPT_IN, AUTOMATED_DECISION_MAKING_OPT_IN, TRACKING_OPT_IN',
  '7.76.0': 'Adds access redaction support',
  '7.77.0':
    'Adds support for request types CONTACT_OPT_IN, CUSTOM_OPT_OUT, CUSTOM_OPT_IN',
  '7.78.0': 'Adds support for Shopify OAuth',
  '7.79.0': 'Removes Shopify endpoint.',
  '7.80.0': 'Adds support for DynamoDB content classification.',
  '7.81.0':
    'Adds additional context for tracing request logs in sombra by Transcend request ID.',
  '7.82.0': 'Adds native support for opt in/out of sensitive data use.',
  '7.83.0':
    'Adds support for querying opt-out status for a list of identifiers and a given opt-out request type.',
  '7.84.0':
    'Adds support for request types to place and remove user from holds.',
  '7.85.0': 'Exposes requestCreatedAt in the cron API.',
  '7.86.0':
    'Ability to set and restore consent preferences through customer-ingress.',
  '7.87.0': 'Adds a new identifier type - braintreeCustomerId.',
  '7.88.0': 'Drop null or empty value while running vectorize sample for SAAS.',
  '7.89.0':
    'Adds support for blocklist to APM tracer via env var: DD_APM_BLOCKLIST.',
  '7.90.0': 'Adds support for Amazon S3',
  '7.91.0':
    'Updates file uploads from integrations to support files that take longer than 35 seconds to upload.',
  '7.92.0':
    'Fixes an issue with manual file uploads, leading to files parsed incorrectly.',
  '7.93.0': 'Adds support for redacting server webhook responses.',
  '7.94.0': 'Adds support for unzipping file streams in proxySaaSFileStream',
  '7.95.0': 'Adds support for S3 Parquet schema discovery',
  '7.96.0': 'Fixes an issue with NULL responses to Database queries',
  '7.97.0':
    'Updates the error messages for missing or invalid Authorization/x-sombra-authorization headers to be more descriptive.',
  '7.98.0': 'Adds support for s3Parquet sample vectorization',
  '7.99.0': 'Updates s3Parquet to support nested folders',
  '7.100.0': 'Upgrades Python from 3.9 to 3.10',
  '7.101.0': 'Upgrades Yarn from 3.0.1 to 3.4.1',
  '7.102.0': 'Switches Sombra base image to Ubuntu 22.04, from Amazon Linux 2.',
  '7.103.0': 'Pin Sombra to Node 14.',
  '7.104.0':
    'Switch Sombra base image back to Amazon Linux 2 from Ubuntu 22.04.',
  '7.105.0':
    'Adds extra protections to enforce each sombra session is scoped to a particular Data Subject.',
  '7.106.0':
    '/v1/enrich-identifiers works without x-transcend-transcend by specifying x-transcend-request-id and x-transcend-enricher-id.',
  '7.107.0': 'MongoDB excludes collections that starts with system in its name',
  '7.108.0': 'Improved logging for SAML validation errors.',
  '7.109.0': 'Upgrade Sombra Docker base image to Amazon Linux 2.0.20230207.2',
  '7.110.0': 'Upgrade Sombra to use Node 16.x',
  '7.111.0':
    'We can now pull metadata for datapoints for the Snowflake integration.',
  '7.112.0':
    'Remove deprecated poorly named decrypt-identifier route in favor of the bulk route transform-to-client-encryption',
  '7.113.0':
    'Added support for mTLS and verifying client requests on the internal sombra https server',
  '7.114.0':
    'Added new mutator fillIdentifier that can replace parts of a string with multiple identifiers',
  '7.115.0': 'Added ODBC connection pooling for single tenant',
  '7.116.0': 'Updated the typing of TrackingConsent to make purposes optional',
  '7.117.0': 'Expose sombra version in /health route',
  '7.118.0': 'Switch to using BigQuery API instead of ODBC',
  '7.119.0':
    'Added sombra route to create a new consent identifier encryption key for multi-tenant sombras missing the key',
  '7.120.0': 'Added sombra route to reveal consent identifier encryption key',
  '7.121.0':
    'Updated the internal tenant configuration to use organisation audience explicitly.',
  '7.122.0':
    'Updated data mapping code for databases to properly quote identifiers for BigQuery.',
  '7.123.0': 'Added support for AWS Dynamo DB privacy requests.',
  '7.124.0': 'Added support for key file auth for BigQuery',
  '7.125.0':
    'Added x-sombra-response-source header to responses to indicate when the response is proxied',
  '7.126.0': 'Added support to query consent preferences',
  '7.127.0': 'Added endpoint to query users consent preferences-Admin API',
  '7.128.0': 'Added cursor pagination support for BigQuery schema discovery',
  '7.129.0': 'Added a KEEP_ALIVE operation Snowflake fetch schema',
  '7.130.0':
    'Added schema discovery and content classification support for MongoDB nested fields',
  '7.131.0': 'Added additional parameters to Snowflake connection strings',
  '7.132.0':
    'Added endpoint for retrieving Google access token using service account keyfile',
  '7.133.0': 'Updated Snowflake connection strings',
  '7.134.0':
    'We now track the approximate number of bytes each request streams through Sombra.',
  '7.135.0': 'Added support for Databricks Lakehouse privacy requests.',
  '7.136.0': 'Upgrade Sombra to use Amazon Linux 23',
  '7.137.0': 'Upgrade Sombra to use Node18.x',
  '7.138.0': 'Updated bigquery to pull in EU datasets as well',
  '7.139.0': 'Revert to using Node 16.x and Amazon Linux 2',
  '7.140.0': 'Add support for Databricks Lakehouse content classification',
  '7.141.0': 'Upgrade Sombra to use Node18.x and Amazon Linux 23',
  '7.142.0': 'Upgrade MariaDB ODBC driver to version 3.1.19',
  '7.143.0': 'Add support for DynamoDB database enricher',
  '7.144.0':
    'Add support for S3 JSONL schema discovery and content classification',
  '7.145.0':
    'Removes the support from /fetch-schema to send heartbeat requests to Snowflake',
  '7.146.0':
    'Adds a new Transcend-ingress endpoint for decrypting Consent Preference identifiers over a DH channel',
  '7.147.0':
    'Adds a new Transcend-ingress endpoint for encrypting Consent Preference identifiers over a DH channel',
  '7.148.0':
    'Add a new Transcend-ingress endpoint to allow streaming + chunking of text files for unstructured data discovery.',
  '7.149.0': 'Add new plugin Unstructured Data Discovery',
  '7.150.0': 'Add new plugin Unstructured Data Sampling',
  '7.151.0':
    'Add a new Transcend-ingress endpoint to allow streaming & chunking of files from S3, for Unstructured data discovery.',
  '7.152.0': 'Add ability to configure MongoDB timeouts',
  '7.153.0':
    'Add ability to configure access request datapoint visibility in Database integrations',
  '7.154.0': 'Bump the version of the Amazon Linux 2023 base image',
  '7.155.0': 'Add support for JSON file during Unstructured Sampling',
  '7.156.0':
    'Add refetch ability for S3 integrations when subDataPoint returns null',
  '7.157.0': 'Add new endpoint to execute DynamoDB commands',
  '7.158.0':
    'Updates to MongoDB content classification to support larger collections.',
  '7.159.0': 'Add support for IBM DB2 database connection.',
  '7.160.0': 'Add new endpoints to execute AWS commands',
  '7.161.0': 'Add a new proxy endpoint to allow for bulk identifier processing',
  '7.162.0': 'Add support for specifying a region in the BigQuery connection',
  '7.163.0': 'Add new GetBucketLocation command to executeAWSCommand endpoint',
  '7.164.0': 'Add new fetchAzureBlobs endpoint to Sombra',
  '7.164.1': 'Add new fetchAzureContainers endpoint to Sombra',
  '7.165.0': 'Adds profileId to the JWT payload for the cron job integration',
  '7.166.0':
    'Adds compatibility for classifying data using custom regular expressions',
  '7.167.0':
    'Adds compatibility for classifying data using large language model',
  '7.168.0': 'Add new endpoint to execute BigQuery APIs',
  '7.169.0':
    'Add ability to track where in the send email flow a failure is occurring',
  '7.170.0':
    'Adds support for connecting to BigQuery by inheriting the permissions of the sombra service',
  '7.171.0': 'Sombra will now report your choice of KMS provider to Transcend.',
  '7.172.0': 'Add additional sampling strategy for MongoDB schema discovery',
  '7.173.0':
    'Fix issue with Database Enricher, where it was not able to handle values outside of strings',
  '7.174.0': 'Enable SSL for Postgres Database Integration',
  '7.175.0':
    'Add paths and encryptedValue properties to signedIdentifiers to support cross-referencing enriched identifiers',
  '7.176.0':
    'Add the ability for customers to fetch secret environment variable values from Hashicorp Vault',
  '7.177.0':
    'Add additional sampling strategy for MongoDB content classification',
  '7.178.0': 'Fix Amazon S3 not returning all prefixes found in a bucket',
  '7.179.0':
    'Allow for the PUT /v1/data-silo route to take in "profiles" as an optional parameter to simplify DSR response. ' +
    'Everything needed to map the response back to a request and profile can be found within the nonce.',
  '7.180.0':
    'Add /v1/request-identifiers ingress endpoint to retrieve plaintext request identifiers',
  '7.181.0':
    'Added transmission of error messages to our /proxy/redact endpoint.',
  '7.182.0':
    'Allow for the POST /v1/data-silo route to take in "profiles[0].profileId" as an optional parameter to simplify DSR response. ' +
    'Everything needed to map the response back to a request and profile can be found within the nonce.',
  '7.183.0':
    "Use the organization's audience to sign the JWT, during single-tenant configuration sync.",
  '7.184.0':
    'The JWT_ECDSA_KEY can now be properly fetched from Hashicorp Vault using the instructions in our documentation ',
  '7.185.0': 'Setup a job scheduler within Sombra to run classification jobs',
  '7.186.0':
    'Fix issue when reconnecting Integrations, where non-required form fields were not getting cleared up even when left empty.',
  '7.187.0': 'Fix Microsoft SQL Schema Discovery SQL query',
  '7.188.0':
    'Enable consent identifier encryption and decryption for data subjects',
  '7.189.0':
    'Adds new endpoint to handle cross-referencing enriched identifiers to Sombra',
  '7.190.0':
    'Add /v1/identifiers/:sombraAudience/regexes endpoint to enable pre-flight regex validation for identifiers',
  '7.191.0': 'Use AWS KMS to generate random byte information, if supported.',
  '7.192.1':
    'Adds the ability to specify only a subset of data categories as label for the LLM classifier',
  '7.192.2':
    'Adds normalization and validation logic to /v1/enrich-identifiers',
  '7.193.0': 'Added parent category to the LLM classifier',
  '7.194.0': 'Adds signedEncryptedIdentifiers to SignedIdentifiersInput type',
  '7.195.0':
    'Allow dataSubject consent identifier encryption and decryption based on environment variable',
  '7.196.0': 'Adds endpoint for refreshing the dataSubCategories cache',
  '7.197.0':
    'Adds new route to generate Content Encryption Key(CEK) when consent Identifier is provided.',
  '7.198.0': 'Fixes destructuring bug in /v1/enrich-identifiers',
  '7.199.0':
    'Allows for /v1/datapoint route to not require the x-transcend-profile-id header',
  '7.200.0': 'Add new internal endpoint to call the LLM classifier',
  '7.201.0':
    'Fixes bug that allows for /v1/datapoint route to not require the x-transcend-profile-id header',
  '7.202.0':
    'Adds identifier validation & normalization to signProfile & attestToDataSubjectIdentity middlewares',
  '7.203.0':
    'Installs dependencies of the python server sidecar when building the image instead of when starting the server.',
  '7.204.0': 'Handle BigInt values in the DynamoDB integration',
  '7.205.0':
    'Async Sombra jobs now include data sub-categories in LLM classification',
  '7.206.0':
    "Adds two new mutators to generate JWT token for ZoomInfo's authentication API",
  '7.207.0': 'Removes the Sombra endpoint for ZoomInfo authentication',
  '7.208.0':
    'Adds new env variable USE_MYSQL_DATABASE_DRIVER to use MySQL driver for database integrations',
  '7.209.0':
    'Adds new env variable S3_REQUEST_MAX_TIMEOUT to use S3 integrations',
  '7.210.0':
    'Adds two new sombra endpoints to support Schema Discovery and Content Classification plugins for Data Lake: parquet in GCS Integration.',
  '7.211.0': 'Updates and adds new endpoints for Azure Blob Storage',
  '7.212.0':
    'Adds two new sombra endpoints to support Schema Discovery and Content Classification plugins for Data Lake: JSONL in GCS Integration.',
  '7.213.0':
    'Updates Sombra base image to Amazon Linux 2023 - 2023.5.20240805.0',
  '7.214.0':
    'Updates consentPreferences lookup route to return communication preferences, and adds a new PUT route to update communication preferences',
  '7.215.0':
    'Updates AWS getBucketLocation command to accept multiple buckets in a single call',
  '7.216.0': 'Adds model_type to classifyTextWithLLM endpoints',
  '7.217.0':
    'Changes request identifiers endpoint to take a list of request IDs as input',
  '7.218.0':
    'Updates /v1/preferences route request body to accept preference topics and choices',
  '7.219.0': 'Adds Llama Minitron to LLM types; removes comprehend_it',
  '7.220.0':
    'Adds new Sombra internal endpoint to evaluate classifiers against customer data',
  '7.221.0':
    'Utilize GLiNER as default model for internal classify text endpoint',
  '7.222.0': 'Adds SFTP endpoints for integrations.',
  '7.223.0':
    'Fix issue when dealing with BigInt values in the DynamoDB integration',
  '7.224.0': 'Generate LLM prompt in Sombra',
  '7.225.0': "Add support for samples and labels' descriptions in LLM prompt",
  '7.226.0':
    'Add support to retrieve column data type and constraint to check for primary key in Database schema discovery',
  '7.227.0': 'Fix Content Classification for Database Integration',
  '7.228.0': 'Adds fixes for unstructured discovery',
  '7.229.0': 'Modifies sendEmail endpoints to use base64 encoded email bodies',
  '7.230.0': 'Remove default URL for LLM Classifier endpoint',
  '7.231.0':
    'Adds support for passing email address to display on privacy center',
  '7.232.0':
    'Capture if MariaDB/MYSQL table has encryption at rest enabled in table create options',
  '7.233.0': 'Removes deprecated classifier capabilities',
  '7.234.0': 'Add endpoints for SMB integrations',
  '7.235.0': 'Fixes bug in stream file endpoint when unpacking zip files',
  '7.236.0':
    'Fixes "/v1/consent-preferences" route to efficiently return results based on "updatedAt" field',
  '7.237.0': 'Removes deprecated vectorization process',
  '7.238.0':
    'Fixes bug in big query schema discovery when tables are not found',
  '7.239.0': 'Adds support for column data type retrieval for MySQL database',
  '7.240.0':
    'Adds new arguments to the "v1/database/fetch-schema" endpoint that allow specifying the database, schema, and table to fetch the schema for.',
  '7.241.0':
    'Adds an internal endpoint for signing json web tokens for use in log transports',
  '7.242.0':
    'Adds new mutator for identifiers with slashes used in uri in DSRs',
  '7.243.0': 'Adds some missing error handling for Unstructured Data Sampling',
  '7.244.0':
    'Add new bulk endpoint /consent/generateCEKs, which when presented with a list of consent identifiers, will generate a CEK for each.',
  '7.245.0': 'Updates the Amazon Linux and postgresql-odbc versions',
  '7.246.0': 'Updates versions of internal AI-related packages',
  '7.247.0':
    'Implement a new GET endpoint at "/v1/preferences" to allow querying user preferences from the preference store.',
  '7.248.0':
    'When calling the PUT /v1/preferences API, you can now specify "attributes" which are custom fields to tag the consent change event with.',
  '7.249.0':
    'Adds support for double opt-ins using the PUT /v1/preferences API.',
  '7.250.0':
    'Removes internal endpoint for signing json web tokens for use in log transports',
  '7.251.0':
    'Adds support for column data type retrieval for MariaDB database and fix column data type retrieval for MySQL database',
  '7.252.0':
    'Adds improved error handling to DSR webhook forwarding whenever content-type responds with invalid value.',
  '7.253.0': 'Adds the ability to pass locale to PUT /v1/preferences API.',
  '7.254.0': 'Adds log filtering by data silo ID and request ID',
  '7.255.0':
    'Adds Datapoint encryption column to capture table encryption status',
  '7.256.0':
    'Adds support for Google Cloud Spanner content classification plugin.',
  '7.257.0':
    'Adds support for CData ODBC drivers to be used with integrations like SAP Concur.',
  '7.258.0': 'Adds LLM method for unstructured data classification',
  '7.259.0':
    'Fix issue with Database Integration in regards to primary key detection',
  '7.260.0':
    'When calling the PUT /v1/preferences API, you can now specify "metadata" associated with the preference store record.',
  '7.261.0':
    'Adds "/llm/classify-unstructured-text" endpoint to classify unstructured text using named entity recognition',
  '7.262.0':
    'Adds support for Binary type sampling during Content Classification for Postgres and MySQL Database',
  '7.263.0':
    'Improve ODBC error messages to return full list of errors when multiple errors are encountered',
  '7.264.0':
    'Fix LLM classifier issue when classifying dates, making sure it does not mark all dates as Date of Birth',
  '7.265.0': 'Adds support for Qualtrics XM Discover.',
  '7.266.0':
    'Adds internal, customer-facing endpoint to classify unstructured text using named entity recognition',
  '7.267.0': 'Adds support for large XLSX files for Unstructured Discovery',
  '7.268.0':
    'Removes DSR profile identifier from webhook nonce to reduce the size of headers.',
  '7.269.0':
    'Removes deprecated opt-out-statuses route. See POST /v1/preferences/{partition}/query ' +
    'https://docs.transcend.io/docs/api-reference/POST/v1/preferences/(partition)/query',
  '7.270.0': 'Adds support for zip files for Unstructured Discovery',
  '7.271.0':
    'Fix issue with Database Integration when sampling for Binary data type',
  '7.272.0':
    'Updated the build process to remove some dependencies not needed for production use cases',
  '7.273.0':
    'Updated the build process to remove some npm dependencies not needed for production use cases',
  '7.274.0':
    'Fix Google BigQuery bug when selecting `No Region` as location during DSR',
  '7.275.0':
    'Removes some code that lived in the Dockerfile that was used solely for testing.',
  '7.276.0': 'Add check for mismatched backend URL regions when sending emails',
  '7.277.0':
    'Fix Database Integration Primary key detection, when a column contains multiple constraints',
  '7.278.0': 'Added support for Oracle DB integration',
  '7.279.0':
    'Add updates to simplify the connection of Aurora databases during AWS data silo discovery',
  '7.280.0': 'Add support for Amazon Aurora',
  '7.281.0': 'Add support for AWS Organizations integration',
  '7.282.0': 'Add DSR support for Google Cloud Spanner',
  '7.283.0': 'Adds new bulk endpoints for /generateCEKs',
  '7.284.0':
    'Adds support for IAM authentication for Amazon Redshift database connection',
  '7.285.0': 'Fix pagination issues on /v1/request-identifiers',
  '7.286.0': 'Bump the version of the Docker base image and some dependencies',
  '7.287.0':
    'Deeper field level classification for JSON samples for Database integration',
  '7.288.0':
    'Updates the verifyConsentIdentifier middleware to handle email identifiers separately from core identifiers.',
  '7.289.0':
    'Updates the maximum file size for PDFs in Unstructured Discovery to 30MB',
  '7.290.0':
    'Deeper field level classification for JSON samples for Snowflake integration',
  '7.291.0':
    'Deeper field level classification for JSON samples for Google BigQuery integration',
  '7.292.0':
    'Update Binary Type handling for Database Integration duirng Content Classification',
  '7.293.0': 'Add support for optional private key for Google integrations',
  '7.294.0':
    'Make EMPLOYEE_AUTHENTICATION_METHODS and DATA_SUBJECT_AUTHENTICATION_METHODS optional environment variables with defaults',
  '7.295.0': 'Adds support for Jira content classification plugin',
  '7.296.0': 'Adds support for Redis.',
  '7.297.0':
    'Revert changes made to support private key for Google integrations.',
  '7.298.0': 'Add support for optional private key for Google integrations',
  '7.299.0': 'Cleanup some unused files in the Docker container',
  '7.300.0': 'Fix BigQuery undefined "schema" error on get table columns call',
  '7.301.0':
    'Add support for multi-identifiers on query and upsert preferences endpoints',
  '7.302.0':
    'Add support for validating and normalizing identifiers on upsert preferences endpoints',
  '7.303.0':
    'Deeper field level classification for JSON samples for S3JSONL integration',
  '7.304.0':
    'Add support for AWS Organizations filtering by Organization Units',
  '7.305.0':
    'Add support for executing Custom Functions on Sombra.' +
    ' You can disable this feature by setting `CUSTOM_FUNCTION_EXECUTION_ENABLED=false`' +
    ' in your environment variables.',
  '7.306.0':
    'Deeper field level classification for JSON samples for S3Parquet integration',
  '7.307.0': 'Add support for executing SQL with Treasure Data integration',
  '7.308.0':
    'Updates EncryptConsentIdentifier route to pull identifiers from dhEncrypted payload',
  '7.309.0':
    'Add support for query parameter stringify and parser options for API request calls',
  '7.310.0':
    'Deeper field level classification for JSON samples for AmazondynamoDb integration',
  '7.311.0':
    'Add support for specifying request data silo status via /v1/data-silo',
};

// Ensure ordered
export const SORTED_CHANGELOG = Object.entries(CHANGELOG)
  .sort(([v1], [v2]) => compareVersions(v1, v2))
  .reverse();

/* eslint-enable max-len */
/* eslint-enable max-lines */
