import { hexToRgb } from '@main/theme-types';
import styled, { css, DefaultTheme, keyframes } from 'styled-components';

interface StyledButtonProps {
  /** Applies checked styling */
  $checked: boolean;
  /** Applies disabled styling */
  $disabled?: boolean;
  /** Applies loading styling */
  $loading?: boolean;
  /** Styled-components theme set up by a theme provider in each app */
  theme: DefaultTheme;
}

/** Rotation keyframes for loading state animation */
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const hexToHalfOpacity = (hexColor: string): string => {
  const { r, g, b } = hexToRgb(hexColor);
  return `rgba(${r}, ${g}, ${b}, 0.5)`;
};

/**
 * Color properties used inside the Switch
 */
type SwitchColorProp =
  | 'defaultColor'
  | 'primary'
  | 'handleColor'
  | 'focusShadowColor'
  | 'handleShadowColor';

/**
 * Utility function mapping switch color properties to theme
 */
type GetSwitchColorsMap = (theme: DefaultTheme) => {
  [key in SwitchColorProp]: string;
};

const getSwitchColorMap: GetSwitchColorsMap = ({ colors }: DefaultTheme) => ({
  /** Theme overridable checked/loading statuses colors */
  primary: colors.primary,
  focusShadowColor: hexToHalfOpacity(colors.primary),
  /** Switch specific colors */
  defaultColor: colors.transcendNavy3,
  handleColor: colors.gray3,
  handleShadowColor: hexToHalfOpacity(colors.transcendNavy3),
});

const getColor =
  (color: SwitchColorProp) =>
  ({ theme }: StyledButtonProps) =>
    getSwitchColorMap(theme)[color];

const handleTransition = css`
  transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
`;

export const StyledButton = styled.button<StyledButtonProps>`
  appearance: none;
  position: relative;
  display: inline-block;

  line-height: 20px;

  cursor: pointer;
  user-select: none;

  margin: 4px;
  padding: 0px;

  height: 10px;
  width: 30px;

  border-radius: 30px;
  border: 1px solid transparent;
  background-color: ${getColor('defaultColor')};

  /* Active style overrides */
  &:active:not([disabled]) {
    &::before {
      width: 24px;
    }
  }

  /* Focused style overrides */
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${getColor('focusShadowColor')};
  }

  /* Switch handle pseudo element */
  &::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 1px;

    margin-left: -5px;

    width: 18px;
    height: 18px;

    border-radius: 18px;
    background-color: ${getColor('handleColor')};
    box-shadow: 0 2px 4px 0 ${getColor('handleShadowColor')};
    ${handleTransition}
  }

  /* Loading circle pseudo element */
  ${({ $loading }) =>
    $loading &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: -2px;
        left: -1px;

        width: 12px;
        height: 12px;

        border-radius: 12px;
        border: 1px solid ${getColor('defaultColor')};
        border-top-color: transparent;
        border-left-color: transparent;
        // Loading animation
        animation: ${rotate} 1s linear infinite;
        ${handleTransition}
      }
    `}

  /* Checked style overrides */
  ${(props) =>
    props.$checked &&
    css`
      background-color: ${getColor('primary')};

      /* Move switch handle when checked */
      &::before {
        margin-left: 5px;
        transform: translateX(-100%);
        left: 100%;
      }

      /* Move loading circle when checked */
      &::after {
        margin-left: -10px;
        transform: translateX(-100%);
        left: 100%;

        /* Update the loading color to match the checked style */
        border: 1px solid ${getColor('primary')};
        border-top-color: transparent;
        border-left-color: transparent;
      }
    `}

    /* Disable style overrides */
  ${({ $disabled }) =>
    $disabled &&
    css`
      &[disabled] {
        cursor: not-allowed;
        opacity: 0.4;

        &::after,::before {
          pointer-events: none;
        }
    `}
`;
